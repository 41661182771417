<template>
	<div  >
		<!-- 机票查询抬头 -->
		<div class="tk-header" >
			<!-- 机票查询弹窗 -->
			<tk-query-card @queryTk="queryTk" @changeTripType="changeTripType"></tk-query-card>
			
			
		</div>
	
		<!-- 机票内容 -->
		<div id="queryTkList-content" style="height: 2px;"></div>
		<div class="tk-content " >
			<!-- 主体内容 -->
			<div class="tk-content-main" >
				<div v-show="loadingFlightList > 0" style="padding-top: 15px;">
						
					
					<!-- 七天日期价格   -->
					<seven-day-price 
						v-show="loadingFlightList!=1 && flightMap.weekPrice && flightMap.weekPrice.length>0 "
						:weekPrice="flightMap.weekPrice" 
						:chooseDate="isQueryTravelType == 2?tkQuery.arrDate:tkQuery.depDate"
						@changeSevenDate="changeSevenDate"
						:maxDay="bookDateRange.maxDay"
						:minDay="bookDateRange.minDay"
						:dateQuery="dateQuery"
						:isQueryTravelType="isQueryTravelType"
						></seven-day-price>
					<!-- 单程航班列表 header  -->
					<flight-list-header v-show='tkQuery.travelType == 1 ' :tkQuery="tkQuery"></flight-list-header>
					<!-- 往返程切换 -->
					<flight-list-travel-type 
						v-show='tkQuery.travelType == 2'
						:tkQuery="tkQuery"
						:isChooseTravelOne="isChooseTravelOne"
						@changeIsQueryTravelType="changeIsQueryTravelType"
						></flight-list-travel-type>
					<!-- 正在查询航班 -->
					<flight-list-loading :loading="loadingFlightList == 1"></flight-list-loading>
					
					
					<!-- 筛选 -->
					<div ref="scrollFilter" class="flight-filter" :class="{'flight-filter-fixed':isFixedFlightFilter,}" style="margin-bottom:3px ;" >
						<tk-filter
							v-show="loadingFlightList == 2 && isQueryTravelType == 1"
							:tkFilterMap="tkFilterMap"
							:sortType="tkQuery.sortType"
							@changeTkFilterMap="changeTkFilterMap"
							@chooseSortItem="chooseSortItem"
						></tk-filter>
						<tk-filter
							v-show="loadingFlightList == 2 && isQueryTravelType == 2"
							:tkFilterMap="tkFilterMapBack"
							:sortType="tkQuery.sortTypeBack"
							@changeTkFilterMap="changeTkFilterMap"
							@chooseSortItem="chooseSortItem"
						></tk-filter>
						<!-- 侧边栏 -->
						<aside-right v-show="tkQuery.tripType == 1" :tkQuery="tkQuery"></aside-right>
					</div>
					<!-- 航班列表 -->
					<div  v-show="loadingFlightList == 2 ">
						
						<flight-list 
							v-show="tkQuery.isQueryTravelType == 1"
							:flightList="flightList" 
							:flightMap="flightMap" 
							:priceMap="flightMap.priceMap"
							:planeMap="flightMap.planeMap" 
							@chooseFlight="toChooseFlight"
							:chooseFlight="chooseFlight"
							:tkQuery="tkQuery"
							>
						</flight-list>
						
						
						<qu-flight-item
						  v-if="tkQuery.isQueryTravelType == 2"
						  :chooseFlight="chooseFlight"
						  @changeChooseFlight="changeChooseFlight"
						 >
							
						</qu-flight-item>
						
						<flight-list-back
							v-show="tkQuery.isQueryTravelType == 2"
							:flightList="flightListBack"
							:flightMap="flightMapBack" 
							:priceMap="flightMapBack.priceMap"
							:planeMap="flightMapBack.planeMap" 
							@chooseFlight="toChooseFlight"
							:tkQuery="tkQuery"
							>
							
						</flight-list-back>
						<el-empty :image-size="200" v-show="filterFlightList.length==0"></el-empty>
						<!-- 无责声明 -->
						<flight-no-debt></flight-no-debt>
					</div>
				</div>
				
			</div>

		</div>
		<!-- 机票底部 -->
		<div class="tk-footer">
			
		</div>
		
		<!-- 违背原因选择 -->
		<yj-violation 
			:vltShow="isShowWeiBei" 
			:vltReason="violateItem" 
			byNumber="10901" 
			@chooseReason="chooseReason"
			@cancelReason="cancelReason"
			></yj-violation>

		<!-- 推荐航班 -->

		<tui-jian-flight-card
			:isShow="isShowTuiJianFlight"
			:recomFlight="recomFlight"
			@toBookFlightItem="toBookFlightItem"
			@contiuteBook="contiuteBook"
			@closeAlert="closeAlert"
			@goBackQueryPage="goBackQueryPage"

		>

		</tui-jian-flight-card>	

	</div>
</template>

<script>
	import { mapGetters, mapMutations, mapState } from 'vuex'
	// // 局部加载条 用于查询动画处理
	// import TKNProgress from 'nprogress'
	// import 'nprogress/nprogress.css'

	
	// 机票查询弹框
	import TkQueryCard from './tkComponent/tkQuery/TkQueryCard.vue'
	

	// 七天日期价格
	import SevenDayPrice from './tkComponent/tkQuery/SevenDayPrice.vue'
	// 去程航班查询信息总结
	import FlightListHeader from './tkComponent/tkQuery/FlightListHeader.vue'
	// 往返程航班查询信息总结
	import FlightListTravelType from './tkComponent/tkQuery/FlightListTravelType.vue'
	// 已选去程航班信息
	import QuFlightItem from './tkComponent/tkQuery/QuFlightItem.vue'
	// 查询骨架图
	import FlightListLoading from './tkComponent/tkQuery/FlightListLoading.vue'
	// 航班筛选
	import TkFilter from './tkComponent/tkQuery/TkFilter.vue'
	// 差旅政策侧边栏  和 筛选同步
	import AsideRight from './tkComponent/tkQuery/AsideRight.vue'
	// 航班列表去程
	import FlightList from './tkComponent/tkQuery/FlightList.vue'
	// 航班列表返程
	import FlightListBack from './tkComponent/tkQuery/FlightList.vue'
	// 航班信息弹窗
    // 航班信息免责声明
	import FlightNoDebt from './tkComponent/tkQuery/FlightNoDebt.vue'

	// 推荐航班信息弹窗
	import TuiJianFlightCard from './tkComponent/tkQuery/TuiJianFlightCard.vue'
   
	// 机票模块公共js文件
	import mixin from '@/mixin'
	
	import tkCommonJs from './tkCommon/tkCommon.js'
	export default {
		name: 'TkQuery',
		components: {
			'tk-query-card': TkQueryCard,
			'flight-list-loading':FlightListLoading,
			'flight-list': FlightList,
			'flight-list-back':FlightListBack,
			'qu-flight-item':QuFlightItem,
			'tk-filter':TkFilter,
			'seven-day-price':SevenDayPrice,
			'aside-right':	AsideRight,
			'flight-list-header':FlightListHeader,
			'flight-list-travel-type':FlightListTravelType,
			'flight-no-debt':FlightNoDebt,
			'tui-jian-flight-card':TuiJianFlightCard
		},
		mixins:[mixin.ccsqdMixin],
		data() {
			return {

				depKeyword: '',
				arrKeyword: "",
				maxLength:9,
				visibleCxrList:false, // 是否显示出行人
				visibleApplication:false, // 是否显示出差申请单
				isShowWeiBei:false, // 是否显示违背弹层
				violateItem:'',
				isFixedFlightFilter:false, // 航班筛选条件固定
				// loadingFlightList:0, // 加载航班列表   0 ：未查询  1：正在查询  2.查询有结果 3. 查询无结果 4.查询异常
				recomFlight:{}, // 推荐航班、舱位
				isShowTuiJianFlight:false, // 是否显示推荐航班、舱位
			
			}
		},
		computed: {
			...mapState({
				tkQuery: state => state.tk.tkQuery,
				flightList: state => state.tk.flightList,
				flightMap: state => state.tk.flightMap,
				tkFilterMap:state => state.tk.tkFilterMap,
				chooseFlight:state =>state.tk.chooseFlight,
				chooseFlightBack:state =>state.tk.chooseFlightBack,
				flightListBack: state => state.tk.flightListBack,
				flightMapBack: state => state.tk.flightMapBack,
				tkFilterMapBack: state => state.tk.tkFilterMapBack,
				cxrList:state=>state.tk.cxrList,
				
				
			}),
			...mapGetters({
				getVipParamterByParNo:'common/getVipParamterByParNo',
			}),
			// 因公因私参数控制
			vipParams() {
				/**
				 *   t系统   1因私开启 2因公开启
				 *   et  1因公开启，2因私开启  这里做一下换
				 */
				return this.getVipParamterByParNo('20011',3);  //  与t系统参数相反 // 火车票因公 ， 因私 开启
			},

			// 当前正在查询的去程，返程
			isQueryTravelType(){
				return this.tkQuery.isQueryTravelType
			},
			// 是否已经选择了去程
			isChooseTravelOne(){
				return (this.chooseFlight && this.chooseFlight.flightNo)&& true
			},
			loadingFlightList(){
				return this.tkQuery.loadingFlightList
			},
		
			// 企业信息
			vipCorp(){
				return this.$common.getUserInfo().vipCorp || {}
			},
			// 当前登录人信息
			empInfo(){
				return this.$common.getUserInfo().empInfo || {}
			},
			// 计算可显示航班数量
			filterFlightList(){
				let newFlightList = [];
				newFlightList = this.flightList.filter((flightItem)=>{
					return !flightItem['isNoShow']
				})
				return newFlightList
			},
			
			// 日期最小，和最大值对象
			bookDateRange(){
				// 方法来自于mixins
      		    let dateRangeObj = this.getBookDateRange(10901,this.tkQuery.tripType,365,this.tkQuery.ccsqdId,this.tkQuery.routeId)
				return dateRangeObj
			},
			// 日期查询
			dateQuery(){
				let obj={
					depszm:this.tkQuery.depszm,
					arrszm:this.tkQuery.arrszm,
					// depDate:this.tkQuery.depDate,
					// arrDate:this.tkQuery.arrDate
				};
				return obj
			},

		},
		created() {
			
		},
		mounted() {
			
			// dom加载完后,进行监听配置
			this.$nextTick(()=>{
				this.initQuery();
				// 1. 监听查询航班请求
				// TKNProgress.configure({
				// 	parent:"#queryTkList-content",
				//     easing: 'ease',  // 动画方式    
				//     speed: 500,  // 递增进度条的速度    
				//     showSpinner: false, // 是否显示加载ico
				//     trickleSpeed: 200, // 自动递增间隔    
				//     minimum: 0.3 // 初始化时的最小百分比
				// })
				// 2. 航班列表滚动监听
				this.addScrollListen();
				
				// 是否自动查询
				if(this.tkQuery.isAutoQuery == 1){
					this.queryTk();
				}else{
					// 判断是否初始化
				}

			})
			
			
		},
		// 路由离开生命周期函数
		beforeRouteLeave(to, from, next) {
		  // 即将跳转的路由地址
		  next();
		},
		// 路由进入生命周期函数
		beforeRouteEnter(to, from, next) {
			if(to.path.indexOf('tkZcQuery')>-1 || to.path.indexOf('tkQuery')>-1){
				next(vm=>{
					vm.initQueryParamByRoute(vm,to)
				});
			}else{
				next();
			}
			
		},
		destroyed(){
			// 移除滚动监听
			this.removeScrollListen();
		
		},
		watch:{
			
		},
		methods: {
			
			...mapMutations({
				SETTkQuery: 'tk/setTkQuery',
				SETFlightList: 'tk/setFlightList',
				SETFlightMap: 'tk/setFlightMap',
				SETChooseFlight: 'tk/setChooseFlight',
				SETTkFilterMap:"tk/setTkFilterMap",
				SETCxrList:"tk/setCxrList",
				
				SETFlightListBack: 'tk/setFlightListBack',
				SETFlightMapBack: 'tk/setFlightMapBack',
				SETChooseFlightBack: 'tk/setChooseFlightBack',
				SETTkFilterMapBack:"tk/setTkFilterMapBack",
				SETTkNhrh:"tk/setTkNhrh",
				
			}),
			// 初始化路由查询参数
			initQueryParamByRoute(vm,to){
				// 如果路由是政采查询，或者路由的机票查询类型是政采查询
				var tkType =  0;
				if( (to.path && to.path.indexOf('tkZcQuery')>-1 ) || ( to.query && to.query.tkType == 1)){
					tkType = 1;
				}
				vm.SETTkQuery({
					tkType:tkType
				});
			},
			
			// 初始化查询参数
			initQuery(){
				//10999  是否按出差申请单进行管控   默认  0：不管控 1：按申请单预算管控 2：按差旅标准管控
				var gkfsRole = this.getVipParamterByParNo('10999',0);
				// 是否开启隐藏头等、公务舱参数控制  默认不开启隐藏  '0'
				var firstAndBussinessGK = this.getVipParamterByParNo('10997','0');
				// 是否开启隐藏高端经济舱参数控制   默认不开启隐藏  '0'
				var hightEconomyGK  = this.getVipParamterByParNo('20028','0');
				// 是否开启最低价控制
				var isOnlyMinPriceGk  = this.getVipParamterByParNo('10994','0'); 
				// 是否开启同一个舱位类型最低价格管控
				var isOnlySameCabinMinPriceGk  = this.getVipParamterByParNo('20040','0'); 
				
				this.SETTkQuery({
					gkfsRole:gkfsRole,
					firstAndBussinessGK:firstAndBussinessGK,
					hightEconomyGK:hightEconomyGK,
					isOnlyMinPriceGk:isOnlyMinPriceGk,
					isOnlySameCabinMinPriceGk:isOnlySameCabinMinPriceGk, // 相同舱位等级最低价控制
					appBook:this.empInfo.appBook,
					loadingFlightList:0, // 修改为未查询
					bookStyle:this.vipCorp.bookStyle,
					isQueryTravelType:1,//默认修改查去程
				});
				// 获取当前服务商是否开启南航一键入会
				this.getBParam();
				// 获取因私协议,是否可以预订控制控制
				this.getTripTypeParam()
				// 获取服务费展示方式
				this.getServiceShowType();
			},
			
			addScrollListen(){
				window.addEventListener("scroll",this.handleScroll);
			},
			removeScrollListen(){
				window.removeEventListener('scroll', this.handleScroll)
			},
			// 滚动监听处理操作
			handleScroll(){
				 let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				        if (scrollTop > 0) {
				          //滚动大于0的时候要做的操作
				        }
				        if (scrollTop > 400) {
				          //大于200的时候要做的操作
						  this.isFixedFlightFilter = true;
				        }else{
							this.isFixedFlightFilter = false;
						}

			},
			// 获取因私协议,是否可以预订控制控制
			getTripTypeParam(){
				this.$conn.getConn('commu.getVipCorpParameter')(
				{paramNo:2710},(res)=>{
					var data = res.data || {};
					let tripType1CanBookXieYi = data.value1 || 0; // 2710参数：默认否 1是：
					let tripType1CanBookAirlineCompany = data.value2 || ''; //
					this.SETTkQuery({
						tripType1CanBookXieYi,
						tripType1CanBookAirlineCompany
					})	
				},(err)=>{
					
				})
			},
			// 获取服务费参数展示方式   1  查询和展示  2 填写页展示
			getServiceShowType(){
				this.$conn.getConn('commu.getBParam')(
				{paramNo:12202},(res)=>{
					var data = res.data || {};
					let serviceShowType = data.value1 || 2; // 12202参数：默认否 1是：
					//
					this.SETTkQuery({
						serviceShowType,
						
					})	
				},(err)=>{
					
				})
			},
			
			
			// 获取服务商 南航一键入会管控参数
			getBParam(){
				this.$conn.getConn('commu.getBParam')({paramNo:1334},(res)=>{
					var data = res.data || {};
					if(JSON.stringify(data)=="{}"){
						this.SETTkNhrh(0)
					}else{
						var value = data.value1 - 0; //存数值型
						this.SETTkNhrh(value)
					}
				},(err)=>{
					this.$message.error(err.tips)
				})
			},
			
			// 修改正在查询的状态
			changeIsQueryTravelType(val){
				this.SETTkQuery({
					isQueryTravelType: val
				})
				// 因公因私切换
			},
			// 当因公因私发生变化时，清除航班数据
			changeTripType(val){
				this.SETTkQuery({
					loadingFlightList: 0,
					isAutoQuery:0,
				});
				this.SETFlightMap({});
				this.SETFlightList([]);
				this.SETFlightMapBack({});
				this.SETFlightListBack([]);				
			},
			
			
			// 选择日期
			chooseDate(date) {

				if (Array.isArray(date)) {
					this.SETTkQuery({
						depDate: date[0],
						arrDate: date[1]
					});
				} else {
					this.SETTkQuery({
						depDate: date
					});
				}
			},
			// 修改日期
			changeSevenDate(date){
				if(this.tkQuery.isQueryTravelType == 2){
					this.SETTkQuery({
						arrDate: date
					});
				}else{
					this.SETTkQuery({
						depDate: date
					});
				}
				
				// 切换日期查询航班
				this.queryTk();
				
			},
			// 验证查询参数
			validQuery(){
				var obj = {
					bool:true,
					tips:"",
				}
				if(!this.tkQuery.depszm  || !this.tkQuery.dep){
					obj.bool = false;
					obj.tips = '请选择出发城市!'
					return obj
				}
				if(!this.tkQuery.arrszm  || !this.tkQuery.arr){
					obj.bool = false;
					obj.tips = '请选择到达城市!'
					return obj
				}
				
				if(!this.tkQuery.depDate ){
					obj.bool = false;
					obj.tips = '请选择出发日期!'
					return obj
				}else{
					if(this.$common.getDiffDay3(new Date(this.tkQuery.depDate),new Date()) < 0 ){
						obj.bool = false;
						obj.tips = '出发日期不能小于今天!'
						return obj
					}
				}
				
				if(this.tkQuery.travelType == 2 && !this.tkQuery.arrDate ){
					obj.bool = false;
					obj.tips = '请选择返程日期!'
					return obj
				}
				
				if(this.tkQuery.tripType == 1 ){
					if(this.cxrList.length == 0){
						obj.bool = false;
						if(this.tkQuery.bookStyle == 1){
							obj.tips = '您当前是因公模式预订,请选择出行人!'
						}else{
							obj.tips = '您当前是因公模式预订,请选择出差单!'
						}
						
						return obj
					}
				}
				
				return obj				
			},
			// 查看经停机票
			queryStopFlightInfo(flightItem){
				if(!flightItem.stopList ){
					let queryStringStop = {
					  "date": flightItem.departDate,
					  "flightNo": flightItem.flightNo
					};
					let queryUrlStop = 'tkQuery2023.queryFlightStop';
					this.$conn.getConn(queryFlightUrl,{loading:true})(queryStringStop,(res) => {
						let data = res.data || {};
						flightItem.stopList = data.stopList || [];
					},err=>{
						
					})
				}
			
			
			},
			// 查询机票
			queryTk(bool) {
				// TKNProgress.start();
				// 查询前校验查询参数：
		
				var validObj = this.validQuery();
				
				if(!validObj.bool){
					this.$message.error(validObj.tips)
					return false
				}
				
				
				// 设置正在查询
				this.SETTkQuery({
					loadingFlightList: 1,
					isAutoQuery:0,
				});
				// 默认查询去程
				var queryString = {
					"arriveAirport": this.tkQuery.arrszm,
					"departAirport": this.tkQuery.depszm,
					"departDate": this.tkQuery.depDate,
					"tripType": this.tkQuery.tripType,
					"prdType": this.tkQuery.tkType == 1 && this.tkQuery.tripType == 1? 'G' : 'C', //G为政采票，C为普通票,
				};
				// 如果当前为返程
				if(this.tkQuery.isQueryTravelType == 2){
					queryString.arriveAirport = this.tkQuery.depszm;
					queryString.departAirport = this.tkQuery.arrszm;
					queryString.departDate = this.tkQuery.arrDate;
				}
				// 清空返程航班
				if(this.tkQuery.isQueryTravelType == 1 && this.tkQuery.travelType== 2){
					this.SETFlightListBack([]);
				}
				
				// 因公模式下，获取职级信息
				if(this.tkQuery.tripType == 1){
					queryString.empRank = this.tkQuery.empRank;
				}
				// 因公、出差申请单模式下，查询传值差旅信息参数
				if(this.tkQuery.tripType == 1 && this.vipCorp.bookStyle == 2){
					queryString.ccsqdNo = this.tkQuery.ccsqdNo;
					queryString.ccsqdId = this.tkQuery.ccsqdId;
					queryString.routeId = this.tkQuery.routeId;
				}
				// var queryFlightUrl ='tkBook.queryFlight';
				var queryFlightUrl ='tkQuery2023.queryFlight4';
				this.$conn.getConn(queryFlightUrl,{loading:false})(queryString,(res) => {
					
					let data = res.data || {};
					
					//因公模式匹配差旅标准
					if(this.tkQuery.tripType == 1 && data.cacheId && this.empInfo.appBook != 1){
						this.matchClbz(data)
					}else{
						this.handleFlightListData(data);
					}
					
				},(err) => {
					this.$message.error(err.tips);
					this.SETTkQuery({loadingFlightList: 4});
					// TKNProgress.done();
				})
			},
			
			//匹配差标
			matchClbz(data) {
			  var priceMap = data.priceMap || {};
			  var newPriceMap = {};
			  var queryString = {
			    "cacheId": data.cacheId,
			    "minZj": this.tkQuery.empRank || 0,
			  };
			  if (this.tkQuery.gkfsRole && this.tkQuery.gkfsRole == 1) {
			    queryString.budget = this.tkQuery.fyys;
			    queryString.cabinCode = this.tkQuery.cabin;
			  }
			  // 因公、出差申请单模式下，查询传值差旅信息参数
			  if(this.tkQuery.tripType == 1 && this.vipCorp.bookStyle == 2){
			  	queryString.ccsqdNo = this.tkQuery.ccsqdNo;
			  	queryString.ccsqdId = this.tkQuery.ccsqdId;
			  	queryString.routeId = this.tkQuery.routeId;
			  }
			  // var matchClbzUrl = 'tkBook.matchClbz2';
			  var matchClbzUrl = 'tkQuery2023.matchClbz';
			  this.$conn.getConn(matchClbzUrl)(queryString,(res)=>{
				  var wbdata = res.data || {};
				  for (var pKey in priceMap) {
				    var priceObj = this.$common.deepCopy(priceMap[pKey]);
				    var wbPriceObj = wbdata[pKey] || {};
				    newPriceMap[pKey] = Object.assign(wbPriceObj, priceObj);
				  		
				  }
				  data.priceMap = newPriceMap;
				  this.handleFlightListData(data);
			  },err=>{
				   this.handleFlightListData(data);
			  })
			 
			
			},
			// 存储、并处理航班数据
			handleFlightListData(data){
				data.flightMap = data.flightMap || data.flightList;
				let flightList = this.$common.objToArr(data.flightMap || {});
				// 处理航班列表，重新排序格式
				let newFlightList = tkCommonJs.chuLiFlightCabinPriceArr(flightList, data.priceMap,data.planeMap,data.flightMap,this.tkQuery);
				console.log(newFlightList)
				// 如果是返程
				if(this.tkQuery.isQueryTravelType == 2){
					this.SETFlightMapBack(data);
					this.SETFlightListBack(newFlightList);
					
				}else{
					this.SETFlightMap(data);
					this.SETFlightList(newFlightList);
				}
			
				if(newFlightList.length > 0){
					// 加载航班信息
					this.SETTkQuery({loadingFlightList: 2});
					
				}else{
					this.SETTkQuery({loadingFlightList: 3});
					
				}
				
				// 1.收集下筛选参数
				// 2.处理航班列表舱位列表不同几个类型展示
				// 3.存储至store缓存中
				
				let tkFilterObj = tkCommonJs.getTkFilterData(newFlightList);
		
				if(this.tkQuery.isQueryTravelType == 2){
					this.SETTkFilterMapBack(tkFilterObj);
				}else{
					this.SETTkFilterMap(tkFilterObj);
				}
				
				// TKNProgress.done();
			},
			/**/ 
			// 选择航班 和舱位
			toChooseFlight(flightItem) {
				// 如果是返程的，去返程列表的查询缓存
				if(this.tkQuery.isQueryTravelType == 2){
					flightItem.cacheId = this.flightMapBack.cacheId;
					this.SETChooseFlightBack(flightItem);
				}else{
					flightItem.cacheId = this.flightMap.cacheId;
					this.SETChooseFlight(flightItem);
				}
				var validCabinUrl = 'tkQuery2023.validCabin';
				var queryObj = {
				  "combinedPrice": false,
				  "flightList": [
					{
					  "cacheId": flightItem.cacheId,
					  "priceId": flightItem.chooseCabin.priceId
					}
				  ]
				};
				this.$conn.getConn(validCabinUrl)(queryObj,(res)=>{
						let data = res.data || {};
						let validFlightList = data.flightList || [];
						/**
						 *  cabin:"D"
							enabled: true
							flightNo: "KN5737"
							msg:"舱位验证返回"
							seat: "A"
						 * **/ 
						for (let i = 0; i < validFlightList.length; i++) {
							let flight = validFlightList[i] || {};
							if (!flight.enabled) {
								this.$alert('抱歉，您选择的座位已售罄，请选择其他舱位进行预订','温馨提示',{
									type:"info",
									confirmButtonText: '我已知晓',
									callback: action => {
										this.queryTk();
									}
								})
								
								return false;
							}
						}
						
						//1. 正常预订 不违背  2. 违背 预订提醒  3.抢票  4.不可预订
						var bookType = flightItem.chooseCabin.bookType;
						if(bookType == 1){
							this.goToTkEdit();
						}else if(bookType == 2){

							this.violateItem =  flightItem.chooseCabin.violateItem;
							
							  // 超标情况下，前后几小时，获取推荐航班
							if(flightItem.chooseCabin.violateCode.indexOf('11106')> -1){
								this.getTuiJianFlight(flightItem,()=>{
									// 如果违背
									if(tkCommonJs.isWei(flightItem.chooseCabin)){
										this.showWeiBeiChoose();
									}else{
										this.goToTkEdit();
									}

									
								})	
							}else{
								// this.recomFlight = flightItem;
								// this.isShowTuiJianFlight = true;
								
								// return 
								this.showWeiBeiChoose();
								
								
							}

						}else if(bookType == 3){
							this.$alert('当前舱位已售罄，您是否要去抢票？若需要抢票,请使用移动端抢票！','温馨提示',{
								type:"info"
							})
							console.log('当前舱位已售罄，您是否要去抢票？若需要抢票,请使用移动端抢票！')
						}else if(bookType == 4){
							this.$message.alert({
								title:"温馨提示",
								content:"当前舱位违背差标，根据贵司差旅预订标准，您不可预订！"
							})
							console.error('当前舱位违背差标，根据贵司差旅预订标准，您不可预订！')
						}	
				},err=>{
					if (err.errCode == '8010') {
					  this.SETTKLIST({
					    isSearch: 1
					  })
					  this.$alert(err.tips, '温馨提示', {
					            confirmButtonText: '重新查询航班',
					            callback: action => {
									// 重新查询
									this.SETTkQuery({loadingFlightList: 0,isQueryTravelType:1});
									this.queryTk();
					             // loadingFlightList: 0,  // 加载航班列表   0 ：未查询  1：正在查询  2.查询有结果 3. 查询无结果 4.查询异常
					             // isQueryTravelType:'1',  // 当前正在查询去程、还是返程
					            }
					          });
					  
					}else{
						this.$alert(err.tips,'温馨提示',{
							type:"info",
						})
					}
								   
				})
				
				
			
			},
			// 显示违背原因选择
			showWeiBeiChoose(){
				this.isShowWeiBei = true;
			},
			// 获取前后几小时，推荐航班
			getTuiJianFlight(flightItem,succCallback){
				var queryString = {
					cacheId:flightItem.cacheId,
					priceId:flightItem.chooseCabin.priceId
				}
				var recomFlight = this.$conn.getConn('tkQuery2023.recomFlight');
				recomFlight(queryString, (res) => {
				var data = res.data || {};
				// recomCabin (Cabin, optional): 推荐舱位 ,
				// recomFlight (Flight, optional): 推荐航班 ,
				// recomPrice (Price, optional): 推荐价格
				let recomFlight = data.recomFlight || {};
				let recomCabin = data.recomCabin || {};
          		let recomPrice = data.recomPrice || {};
				if(recomFlight.flightNo){
					this.recomFlight = {
						...recomFlight,
						chooseCabin:{
							...recomCabin ,
							...recomPrice ,
							coverPriceId:flightItem.chooseCabin.priceId,  // 用于前后几小时对比价格
						},
						cacheId:flightItem.cacheId,
						recommend:true,
					};
					this.isShowTuiJianFlight = true;
				}else{
					// this.recomFlight = {
					//   ...this.currentFlight,
					//   chooseCabin:{
					//     ...this.chooseCabin
					//   }
					// }
					// this.isShowTuiJianFlight = true;
					succCallback && succCallback()
				}

				}, function(err) {
					if (err.errCode == '8010' || err.errCode == '5005') {
					  this.SETTKLIST({
					    isSearch: 1
					  })
					  this.$alert(err.tips, '温馨提示', {
					            confirmButtonText: '重新查询航班',
					            callback: action => {
									// 重新查询
									this.SETTkQuery({loadingFlightList: 0,isQueryTravelType:1});
									this.queryTk();
					             // loadingFlightList: 0,  // 加载航班列表   0 ：未查询  1：正在查询  2.查询有结果 3. 查询无结果 4.查询异常
					             // isQueryTravelType:'1',  // 当前正在查询去程、还是返程
					            }
					          });
					  
					}else{
						succCallback && succCallback()
					}
		
				})

			},
			/**
			 *  推荐航班、舱位
			 *  @toBookFlightItem="toBookFlightItem"
			 *  @contiuteBook="contiuteBook"  
			 * 	@closeAlert="closeAlert"
			 *  @goBackQueryPage="goBackQueryPage"  重新查询
			 * **/ 
			 closeAlert(){
				this.isShowTuiJianFlight = false;
			 },
			 // 重新查询
			 goBackQueryPage(){
				this.isShowTuiJianFlight = false;
				// 重新查询
				this.SETTkQuery({loadingFlightList: 0,isQueryTravelType:1});
				this.queryTk();
			 },
	
			// 预订推荐航班和舱位
			toBookFlightItem(){
			
				this.isShowTuiJianFlight = false;
				
				let newFlightItem = {
					...this.recomFlight,
					chooseCabin:{
						...this.recomFlight.chooseCabin,
						violateItem: this.recomFlight.chooseCabin.violateCode == '11106'?'':(this.recomFlight.chooseCabin.violateItem || '')
					}
				}
				

				// 往返  第二程
				if(this.tkQuery.travelType ==2 && this.tkQuery.isQueryTravelType == 2){
					this.SETChooseFlightBack(newFlightItem);
					
				}else{
					this.SETChooseFlight(newFlightItem);
				}


				this.goToTkEdit();
				
			},
			//  继续预订超标航班和舱位
			contiuteBook(){


				let recomPriceId = this.recomFlight.chooseCabin? this.recomFlight.chooseCabin.priceId:'';
				

		
				// 返程
				if(this.tkQuery.travelType ==2 && this.tkQuery.isQueryTravelType == 2){
					let  newFlightItem = this.$common.deepCopy(this.chooseFlightBack);
					
					newFlightItem = {
						...newFlightItem,
						chooseCabin:{
							...newFlightItem.chooseCabin,
							recomPriceId:recomPriceId,
						}
					}
					this.SETChooseFlightBack(newFlightItem);
					
				}else{
					let newFlightItem = this.$common.deepCopy(this.chooseFlight)
					newFlightItem = {
						...newFlightItem,
						chooseCabin:{
							...newFlightItem.chooseCabin,
							recomPriceId:recomPriceId,
						}
					}
					this.SETChooseFlight(newFlightItem);
				}
				
				this.isShowTuiJianFlight = false;

				this.showWeiBeiChoose();
			},

			// 跳转至机票订单填写页
			goToTkEdit(){
				// 如果是往返查询，且当查询去程后，再去查询返程
				if(this.tkQuery.travelType ==2 && this.tkQuery.isQueryTravelType == 1){
					this.SETTkQuery({isQueryTravelType:2})
					
					//如果没有选中返程，查询一次
					if(this.flightListBack.length == 0 || !this.chooseFlightBack){
						this.queryTk();
					}
					
				}else{
					// 如果是单程，清空之前选择的返程航班数据
					if(this.tkQuery.travelType == 1){
						this.removeChooseFlightBack();
					}
					this.$router.push({
						path: '/yjEdit/tkEdit',
					})
				}
				
				
				
			},
			// 清空返程的航班数据
			removeChooseFlightBack(){
				this.SETChooseFlightBack({});
			},
			
			
			// 选择违背原因回调
			chooseReason(obj){
				let newFlightItem = {};
				// 返程违背
				if(this.tkQuery.travelType ==2 && this.tkQuery.isQueryTravelType == 2){
					newFlightItem = this.$common.deepCopy(this.chooseFlightBack)
					for(var key in obj){
						newFlightItem[key] = obj[key];
					}
					this.SETChooseFlightBack(newFlightItem);
					
				}else{
					newFlightItem = this.$common.deepCopy(this.chooseFlight)
					for(var key in obj){
						newFlightItem[key] = obj[key];
					}
					this.SETChooseFlight(newFlightItem);
				}
				
				this.isShowWeiBei = false;
				
				this.goToTkEdit();
			},
			// 取消违背原因弹窗
			cancelReason(bool){
				this.isShowWeiBei = false;
			},
			
			/**
			 *  筛选操作
			 *  1.
			 *
			 ****/
			changeTkFilterMap(tkFilterMap){
				
				
				// 返程筛选
				if(this.tkQuery.travelType ==2 && this.tkQuery.isQueryTravelType == 2){
					// 执行筛选功能
					let flightList = this.$common.deepCopy(this.flightListBack);
					let newFlightList =tkCommonJs.getFilterNewFlightList(flightList,tkFilterMap); 
					// 更新筛选提交  更新筛选vuex条件，
					this.SETTkFilterMapBack(tkFilterMap);
					
					// 更新航班状态
					this.SETFlightListBack(newFlightList);
				}else{ // 去程筛选
					// 执行筛选功能
					let flightList = this.$common.deepCopy(this.flightList);
					let newFlightList =tkCommonJs.getFilterNewFlightList(flightList,tkFilterMap); 
					// 更新筛选提交  更新筛选vuex条件，
					this.SETTkFilterMap(tkFilterMap);
					
					// 更新航班状态
					this.SETFlightList(newFlightList);
				}
				
			},
			/*
			*  排序操作
			*  sortItemKey 由  keyType + sort 组成 
			*  例： departTime-1   departTime 字段名  1 升序  2 降序
			*/ 
		   chooseSortItem(sortItemKey){
			   if(this.tkQuery.travelType ==2 && this.tkQuery.isQueryTravelType == 2){
				   // 更新sortType
				   this.SETTkQuery({sortTypeBack:sortItemKey})
				   
				   // 执行排序
				   let flightList = this.$common.deepCopy(this.flightListBack);
				   tkCommonJs.getSortNewFlightList(flightList,sortItemKey);
				   
				   			  
				   // 更新航班状态
				   this.SETFlightListBack(flightList);
			   }else{
				   // 更新sortType
				   this.SETTkQuery({sortType:sortItemKey})
				   
				   // 执行排序
				   let flightList = this.$common.deepCopy(this.flightList);
				   tkCommonJs.getSortNewFlightList(flightList,sortItemKey);
				   
				   			  
				   // 更新航班状态
				   this.SETFlightList(flightList);
			   }
			
		   },
		   // 修改选择去程航班
		   changeChooseFlight(){
			   this.SETTkQuery({isQueryTravelType:1})
		   }
			
			

		}
	}
</script>

<style lang="scss" scoped="scoped">
	.tk-header {
		text-align: left;
		background-color: white;

	
	}

	// 航班列表
	.tk-content {
		background-color: #eef1f6;
		padding-bottom: 400px;
		.tk-content-main {
			width: 1200px;
			margin: 0 auto;
			// 用于处理屏幕太矮，内容高度的问题
			min-height: 500px;
			background-color: transparent;
		}
		.flight-filter{
			position: relative;
			height: 60px;
		}
		// 航班筛选固定
		.flight-filter-fixed{
			position: fixed;
			top: 0;
			background-color: white;
			z-index: 1000;
			
		}
	}
	
	.tk-input{
		width: 200px;
	}
</style>
