<template>
	<div class="all-container">
		<div class="tr-main-header">
			<yj-trip-type :tripType="tripType" :vipParams="vipParams" @changeTripType="changeTripType">
			</yj-trip-type>
			<yj-travel-apply :proType="10903" :ccsqdNoOrId="htCnCcsqD.ccsqdId || htCnCcsqD.ccsqdNo"
				:routeId="htCnCcsqD.routeId" v-if="tripType == 1 && bookStyle == 2"
				@chooseTravelApply="chooseTravelApply"></yj-travel-apply>
			<el-input v-model="chooseCXR" v-if="bookStyle == 1 && tripType == 1" class="tr-input"
				@click.native="selectCXR" readonly>
				<i slot="prefix" class="el-input__icon el-icon-user"></i>
			</el-input>
			<!-- <span v-if="cxrLength>1" @click="getCheckInPlan">调整入住方案</span> -->
		</div>
		<ht-cn-search @searchHotel="searchHotel" @getNewCityId="getNewCityId" :loading="htLoading" :bookDateRange="bookDateRange"></ht-cn-search>
		<!-- 单订模式 出行人-->
		<yj-cxr-list :visible="cxrShow" :cxrList="chooseCXRList" @chooseCxrList="chooseCxrList"
			@cancleCxrList="cancelCXR"></yj-cxr-list>
	</div>
</template>

<script>
	import {
mapGetters, mapMutations, mapState
} from 'vuex';
import mixin from './../../../mixin';
import htCnSearch from './htCnSearch';
	export default {
		name: 'htCnQueryCard',
		mixins: [mixin.ccsqdMixin],
		data() {
			return {
				cxrShow: false, // 显示出行人
				chooseCXR: '', // 出行人
				cxrLength: 0, // 多少出行人
				chooseCXRList: [], //选中出行人列边
				planShow: false, // 调整入住方案
				htLoading: false,
			}
		},
		components: {
			htCnSearch: htCnSearch,
			// roomDrag: roomDrag,
		},
		computed: {
			...mapState({
				htCnQuery: (state) => state.htCn.htCnQuery, //
				htCnCcsqD: (state) => state.htCn.htCnCcsqD,
				ccsqdInfo: (state) => state.common.ccsqdInfo, // 出差申请单信息
				htCnParams: (state) => state.htCn.htCnParams,
				htCnSonTags: (state) => state.htCn.htCnSonTags,
				htCnPolicy: (state) => state.htCn.htCnPolicy,
				cxrList: state => state.htCn.cxrList, // 出行人
			}),
			...mapGetters({
				getVipParamterByParNo: 'common/getVipParamterByParNo',
			}),
			// 日期最小，和最大值对象
			bookDateRange() {
				// 方法来自于mixins
				let dateRangeObj = this.getBookDateRange(10903, this.htCnQuery.tripType, 30, this.htCnCcsqD.ccsqdId, this.htCnCcsqD.routeId);
				return dateRangeObj
			},
			tripType() {
				/* 默认当前因公因私*/
				return this.htCnQuery.tripType //1为公 2为私
			},
			// 登录人信息
			userInfo() {
				let userInfo = this.$common.getUserInfo();
				return userInfo;
			},
			empInfo() {
				return this.userInfo.empInfo;
			},
			bookStyle() {
				// 1单订模式  2出差申请单模式
				let bookStyle = this.$common.getUserInfo().vipCorp.bookStyle
				return bookStyle
			},
			//因公因私参数控制
			vipParams() {
				/**
				 *   t系统   1因私开启 2因公开启
				 *   et  1因公开启，2因私开启  这里做一下换
				 */
				let params = this.getVipParamterByParNo(20009, 3)
				return params //  与t系统参数相反 //this.$common.getVipParams('20011',3);  火车票因公 ， 因私 开启
			},
			gkfsRole() {
				return this.htCnQuery.gkfsRole
			},
		},
		mounted() {
           
			// 单订 因公模式下，默认登录人为出行人
			if (this.cxrList.length == 0 && this.bookStyle==1) {
				this.chooseCXRList = [];
				if(this.empInfo.opers && this.empInfo.opers.indexOf(3)<0 ){
					this.empInfo.useCardType ='1'; // 当前出行人使用证件类型  1 身份证  2 护照  3 港澳通行证   4 台胞证  5 回乡证  6 台湾通行证  7 学生证  8 军官证  9 其他证件
					this.empInfo.cxrType = '1'; //当前人的出行人类型   1.员工  2.常旅客 3. 其他
					this.empInfo.psgType = '1'; // 默认 1  1成人 2儿童 3婴儿 
					this.empInfo.cxrName = this.empInfo.empName; //当前出行人的中文名字
					this.chooseCXRList.push(this.empInfo)
					this.SET_CXR_LIST(this.chooseCXRList)
					this.chooseCXR = this.empInfo.empName;
				}

					
			}else if(this.cxrList.length != 0 && this.bookStyle==1){
				this.chooseCXRList = this.cxrList;
				this.SET_CXR_LIST(this.chooseCXRList)
				this.chooseCXR=this.cxrList.map(item=>item.cxrName).join(',')
			}else if(this.bookStyle==2 && this.gkfsRole==2 && this.tripType==1){
				this.chooseCXRList = this.cxrList;
				if (this.cxrList.length > 0 ) {
					let nameList = [];
					this.cxrList.forEach(item => {
						nameList.push(item.cxrName)
					})
					this.chooseCXR = nameList.join(",");
				
				}
			}
            
		},
		methods: {
			...mapMutations({
				SET_HTCN_QUERY: 'htCn/SET_HTCN_QUERY', // 保存因共模式下的自定义信息
				SET_HTCN_PARAMS: 'htCn/SET_HTCN_PARAMS', // 设置查询参数
				SET_HTCN_CCSQD: 'htCn/SET_HTCN_CCSQD', //保存因共模式下的出差申请单信息
				SET_CCSQD_INFO: 'common/setCcsqdInfo', // 全局的出差申请单信息
				SET_HTCN_SONNTAGS: 'htCn/SET_HTCN_SONNTAGS',
				SAVE_HOTELINFO: 'htCn/SAVE_HOTELINFO',
				SET_HTCN_POLICY: 'htCn/SET_HTCN_POLICY',
				COPY_ROOMS:'htCn/COPY_ROOMS',
				SET_CXR_LIST:'htCn/SET_CXR_LIST',
                SET_LSR_LIST:'htCn/SET_LSR_LIST',
			}),
			// 因公因私切换
			changeTripType(tripType) {//因公因私控制的时候，因公模式下使用出行人
				this.SET_HTCN_QUERY({
					tripType: tripType
				})
              if(tripType==2){
                let chooseCXRList = [];
				this.empInfo.useCardType ='1'; // 当前出行人使用证件类型  1 身份证  2 护照  3 港澳通行证   4 台胞证  5 回乡证  6 台湾通行证  7 学生证  8 军官证  9 其他证件
				this.empInfo.cxrType = '1'; //当前人的出行人类型   1.员工  2.常旅客 3. 其他
				this.empInfo.psgType = '1'; // 默认 1  1成人 2儿童 3婴儿 
				this.empInfo.cxrName = this.empInfo.empName; //当前出行人的中文名字
				chooseCXRList.push(this.empInfo)
				this.SET_LSR_LIST(chooseCXRList)
              }

				
			},
			// 选中出差申请单
			chooseTravelApply(obj) {
				//选中的出差申请单
				let routeObj = obj?.currentRoute ?? {};
				
				//rw11486
				var startDate = this.$common.getNewDepartDate({
				  departDate: routeObj.departDate||routeObj.routeStart,
				  routeDateStart: routeObj.routeStart,
				  routeDateEnd: routeObj.routeEnd
				});
				if(startDate === '0000-00-00'){
					this.$message({
						type: 'error',
						message: '出差行程已过期, 无法预订',
					})
				  // return;
					//此处只做行程过期提示， 不在控制代码执行逻辑
					startDate = routeObj.departDate||routeObj.routeStart;
				}
				
				let cxrList = obj?.cxrList ?? [];
				let ccsqdInfo = obj?.detail; // 出差申请单所有信息
				
				let minEmp = this.$common.getMinEmpRank(cxrList); // 获取最低员工职级
				this.SET_CCSQD_INFO({
					ccsqdInfo: ccsqdInfo,
				});
				this.SET_HTCN_CCSQD(this.$common.deepCopy(obj)); //不知道有啥参数后面要用啊 ，先直接全部深拷贝存一遍把
				this.SET_CXR_LIST(cxrList);
				// 默认选择出差申请单的出行人
				this.chooseCXRList = cxrList;
				
				
				
				// 当前出差申请单相关信息存储到vuex
				let queryobj = {
					ccsqdCityName: routeObj.departCityName, // 城市名字
					cityId: routeObj.departCity, //城市或者商圈id
					checkInDate: startDate, //日期开始时间
					// checkOutDate: routeObj.arriveDate, // 日期结束时间
                    keywords:routeObj?.flightNo??''
				}

				queryobj.checkOutDate = routeObj.arriveDate <= queryobj.checkInDate ? 
					this.$common.getDateMing(queryobj.checkInDate, 1): routeObj.arriveDate;
				//如果离店日期超过了行程结束日期，那么将离店日期修改为行程默认的离店日期，由查询页判断入住离店日期是否可预订
				if(queryobj.checkOutDate > routeObj.routeEnd){
				  queryobj.checkOutDate = routeObj.arriveDate;
				}
				let empObj = {
					'empRank': minEmp.empRank || 0, //   职级
					'useNameclbz': minEmp.cxrName || minEmp.empName, // 本次预订使用谁的差旅标准
					'minEmpRankEmpId': minEmp.empId, // 当前员工差旅标准的职级
				}
				
				this.$emit('useNameclbz',empObj.useNameclbz)
				this.$emit('chooseCcsqd',this.$common.deepCopy(obj))
				this.SET_HTCN_QUERY(empObj)
				this.SET_HTCN_PARAMS(queryobj);
				this.getNewCityId();
                // this.getHtClbz();
				// var that = this;
				// that.$common.throttle(that.getHtClbz,1000)();
			},
			// 单订模式下出行人显示
			selectCXR() {
				this.cxrShow = true;
			},
			// 选中出行人 出行人默认是当前登录人
			chooseCxrList(list) {
				if (list.length > 0) {
					let nameList = [];
					list.forEach(item => {
						nameList.push(item.cxrName)
					})
					this.cxrLength = nameList.length;
					this.chooseCXR = nameList.join(",");
				} else {
					this.chooseCXR = '';
					this.cxrLength = 0;
				}
				this.chooseCXRList = list;
				this.SET_CXR_LIST(this.chooseCXRList)
				this.cxrShow = false;
				//单订模式获取职级
				this.getRank()
				// this.getHtClbz();
			},
			// 获取员工 差旅信息
			getRank() {
				// 获取最低员工职级
				let minEmp = this.$common.getMinEmpRank(this.cxrList);
				let queryObj = {
					'empRank': minEmp.empRank || 0, //   职级
					'useNameclbz': minEmp.cxrName || minEmp.empName, // 本次预订使用谁的差旅标准
					'minEmpRankEmpId': minEmp.empId, // 当前员工差旅标准的职级
				}
				this.$emit('useNameclbz',queryObj.useNameclbz)
				this.SET_HTCN_QUERY(queryObj)
			},
			// 取消出行人
			cancelCXR(bool) {
				this.cxrShow = bool;
			},
			// 所搜酒店数据
			searchHotel() {
				if (this.tripType == 1 && this.bookStyle == 1) {
					if (this.chooseCXR == 0) {
						this.$message.warning('请选择出行人')
						return
					}
				}
				// 重新搜索到第一页
				this.SET_HTCN_PARAMS({
					pageNum: 1,
				})
				this.getHtClbz();
				this.$emit('queryHtCn', true)
			},
			// 选择新的城市时 重新获取差旅标准
			getNewCityId(){
				if(this.tripType == 1 && (this.bookStyle == 1 || this.bookStyle == 2)){
					// this.getHtClbz();
					// var that = this;
					// that.$common.throttle(that.getHtClbz,500)();
				}
			},
			// 单订模式下 因公匹配差旅标准
			getHtClbz() {
				let nowDays=this.$moment().format("YYYY-MM-DD")
				let nextDays=this.$moment().add(1,"days").format("YYYY-MM-DD")
				let params = {
					cityId: this.htCnParams.cityId, //城市或者商圈id
					dateStart: this.htCnParams.checkInDate ||  nowDays, //日期开始时间
					dateEnd: this.htCnParams.checkOutDate || nextDays, // 日期结束时间
					ccsqdId:this.htCnCcsqD.ccsqdId,
					routeId:this.htCnCcsqD.routeId
				}
				
				let userInfo = this.$common.getUserInfo();
				params.corpId = userInfo.empInfo.corpId;
				
			// 获取最低员工职级
				let cxrList = this.chooseCXRList || this.cxrList || [], allRank = [],
					minEmpRank = 999999; //企业设置的最低职级;
				let ids = [];
				let roomEmpMap = {};
				for(let k=0; k < cxrList.length; k++){
					let cxrItem = cxrList[k];
					if (cxrItem.empRank == void 0) cxrItem.empRank = 0;
          // foreignType 没有返回也走这个
					if (!cxrItem.foreignType||cxrItem.foreignType == 0) {
						ids.push(cxrItem.empId);
						allRank.indexOf(cxrItem.empRank) == -1 && allRank.push(cxrItem.empRank);
						if(cxrItem.empRank < minEmpRank){
							minEmpRank = cxrItem.empRank;
						}
					} else {
						// 2023-10-30 bug号：11372 陈伟说 外来人使用预订人差标 传入预订人的id 
						ids.push(userInfo.empInfo.empId)
						allRank.indexOf(userInfo.empInfo.empRank) == -1 && allRank.push(userInfo.empInfo.empRank);
						if(userInfo.empInfo.empRank < minEmpRank){
							minEmpRank = userInfo.empInfo.empRank;
						}
					}
				}
				ids.forEach((empId, empIdx) => {
					if (empIdx < 5) {
						roomEmpMap[empIdx + 1] = [empId];
					} else {
						roomEmpMap[empIdx % 5 + 1].push(empId);
						// let key = Math.ceil((empIdx + 1) / 2);
						// if (!roomEmpMap[key]) {
						// 	roomEmpMap[key] = [empId];
						// } else {
						// 	roomEmpMap[key].push(empId);
						// }
					}
				});
				
				params.roomEmpMap = roomEmpMap;
				params.minEmpRank = minEmpRank;
				params.allRank = allRank.join(',');
				params.travelerIds = ids;

				this.$conn.getConn('htbook.travelPolicy2')(params, (res) => {
					let data = res.data || [];
					let policyInfo = !!data.policy ? {policy: data.policy} : {policy: {}};
					// data.forEach((item, index) => {
					// 	if (this.htCnQuery.empRank >= item.empRankS && this.htCnQuery.empRank < item.empRankE) {
					// 		policyInfo = item
					// 	} else if (this.htCnQuery.empRank >= item.empRankE) {  //汪经理说当匹配不到职级的时候取最低职级范围来判断
					// 		let sortData = data.sort((a,b)=>a.empRankS-b.empRankS)
					// 		policyInfo = sortData[0]
					// 	}
					// })
					this.getRank()
					this.SET_HTCN_POLICY(this.$common.deepCopy(policyInfo))
				}, (err) => {

				})
			}

		}

	}
</script>

<style lang="scss" scoped>
	.all-container {
		width: 1200px;
		margin: 0 auto;

		.tr-main-header {
			text-align: left;
			margin-top: 18px;
			line-height: 30px;
			min-height: 32px;
			display: flex;
			.el-input {
				width: 200px;
				margin-left: 20px;
			}

			.tr-input {

				/deep/ .el-input__inner {
					width: 200px;
					height: 34px;
					line-height: 34px;
				}
			}
		}
	}
</style>
