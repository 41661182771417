/*
 *  common.js
 *  公共方法处理
 *
 */
import company from './company.js';
import dateTime from './dateTime.js';


var common = {};

// 将dateTime方法放入common中
common.init = function() {

	for (var key in dateTime) {
		common[key] = dateTime[key]
	}
}
common.init();

// 获取订单来源  obt的订单来源固定  10503
common.getOrderFrom = function() {
	var orderFrom = 10503;
	return orderFrom
}
// 获取总公司 配置信息 行程单展示
common.getCompanyDetails = function(){
	let info = {};
	let curInfo = common.sessionget('pageSettingInfo')
	company.list.forEach(item=>{
		if(item.compId == window.apiconfig.compId){
			info = item;
			info.etServicePhone = curInfo.etServicePhone;
		}
	})
	return info	
}


// 防抖函数  默认300 毫秒
common.debounce2 = function(fn, delay = 300) { //默认300毫秒
	let timer;
	return function() {
		let args = arguments;
		if (timer) {
			clearTimeout(timer);
		}
		timer = setTimeout(() => {
			fn.apply(this, args); // this 指向vue
		}, delay);
	};
}

// let canRun = true; //节流函数是否运行
// // 节流函数  默认300 毫秒
// common.throttle = function(fn , t) {
// 	return function() {
// 		if (!canRun) return
// 		canRun = false
// 		setTimeout(function() {
// 			fn.apply(this, arguments)
// 			canRun = true
// 		}, t);
// 	}
// }

// 缓存存储
common.localset = function(key, value) { //保存到localStorage，value可以为复杂对象
	var storage = window.localStorage;
	storageSet(storage, key, value);
}
common.localget = function(key) { //从localStorage获取值，返回值为String、或者为复杂对象
	var storage = window.localStorage;
	return storageGet(storage, key);
}
common.localremove = function(key) { //从localStorage从删除指定数据
	var storage = window.localStorage;
	storageRemove(storage, key);
}
common.localclear = function() {
	var storage = window.localStorage;
	storageclear(storage);
}

common.sessionset = function(key, value) { //保存到sessionStorage，value可以为复杂对象
	var storage = window.sessionStorage;
	return storageSet(storage, key, value);
}
common.sessionget = function(key) { //从sessionStorage获取值，返回值为String、或者为复杂对象
	var storage = window.sessionStorage;
	return storageGet(storage, key);
}
common.sessionremove = function(key) { //从sessionStorage从删除指定数据
	var storage = window.sessionStorage;
	storageRemove(storage, key);
}
common.sessionclear = function() {
	var storage = window.sessionStorage;
	storageclear(storage);
}

function storageSet(storage, key, value) {
	if (typeof(value) != "string") {
		value = JSON.stringify(value);
	}
	storage.setItem(key, value);
}

function storageGet(storage, key) {
	var value = storage.getItem(key);
	if (!!value && value.indexOf("{") > -1) {
		value = JSON.parse(value);
	}
	return value;
}

function storageRemove(storage, key) {
	storage.removeItem(key);
};

function storageclear(storage) {
	storage.clear();
};
// 判断一个数据是不是字符串
common.isString = function(str) {
	return (typeof str == 'string') && str.constructor == String;
}
// 判断一个对象是不是数组
common.isArray = function(obj) {
    return Object.prototype.toString.call(obj) === '[object Array]';
}
/*
 *判断数据类型    和   数据类型转换
 * */

//判断对象和数组是空，返回true
common.ifObjBlank = function(obj) {
  if (obj == undefined) return true;
  for (var i in obj) return false;
  return true;
}
//判断对象和数组不是空,返回true
common.isObjNotBlank = function(obj) {
  return !common.ifObjBlank(obj);
}

//判断str是否为有效字符串
common.ifStrBlank = function(str, len) {
  if (typeof(str) == "undefined" || str == null || str == "") {
    return true;
  }
  str = str.replace(/^\s\s*/, '').replace(/\s\s*$/, '').toLowerCase();
  if (str == "" || str == "null" || str == "undefined") {
    return true;
  }
  if (typeof(len) != "undefined" && str.length != len) {
    return true;
  }
  return false;
}
common.ifStrNotBlank = function(str, len) {
  return !common.ifStrBlank(str, len);
}
//删除字符串中的空格
common.trim = function(str) {
  return typeof(str) == "string" ? str.replace(/^\s\s*/, '').replace(/\s\s*$/, '') : str;
}

common.trimToEmtry = function(str, def) {
  def = typeof(def) == "undefined" ? "" : def;
  return common.ifStrNotBlank(str) ? common.trim(str) : def;
}
/**
 * 获取地址栏上?后面的参数  ?name=pwl&eg=1
 * 解决弹出层、load页面之间传值
 * */
 function _top(){
   	var tmp = window;
   	while(!!tmp.parent && tmp.self != tmp.parent){
   		tmp = tmp.parent;
   	}
   	return tmp;		
 }
common.getParams = function(namespace) {
	var param = {},
		path, location = _top().location;
	if (location) path = location.search;
	if (path && path.length > 0) { //url参数传值
		var i = path.indexOf("?") + 1,
			paths = path.substr(i) || "";
		if (paths) {
			paths = paths.split("&");
			for (var i = 0, l = paths.length; i < l; i++) {
				var p = paths[i].split("=");
				param[p[0]] = decodeURIComponent(p[1] || ""); //encodeURIComponent decodeURIComponent
			}
		}
	}

	return param
};
// 判断当前字符是不是json字符串
common.isJsonString = function(str) {
    try {
        const obj = JSON.parse(str);
        return typeof obj === 'object' && obj !== null;
    } catch (e) {
        return false;
    }
}

// 修改页面title
common.changeTitle = function(title){
	document.title =  title
}


// 类数组对象转数组
common.objToArr = function(obj) {
	var arr = [];
	obj = obj || {};
	for (var k in obj) {
		arr.push(obj[k]);
	}
	return arr;
};

// 数组、对象的深拷贝
common.deepCopy = function(arrayOrObj) {
	function copy(obj) {
		var newobj = obj.constructor === Array ? [] : {};
		if (typeof obj !== 'object') {
			return;
		}
		for (var i in obj) {
			newobj[i] = (typeof obj[i] === 'object') && obj[i] ? copy(obj[i]) : obj[i];
			if ((typeof obj[i] === 'object') && obj[i]) {
				// 处理Date赋值问题
				if (obj[i] instanceof Date) {
					newobj[i] = obj[i];
				} else {
					newobj[i] = copy(obj[i]);
				}
			} else {
				newobj[i] = obj[i];
			}
		}
		return newobj
	}
	var copyArray = copy(arrayOrObj)
	return copyArray
}


// 获取企业参数
common.getVipParamterByParNo = function(store, code, def,valuename,proType) {
	let getVipParamterByParNo = store.getters['common/getVipParamterByParNo'];
	return getVipParamterByParNo(code, def,valuename,proType)
}




// 判断产品类型 是否显示审批规则   1.开启  2.关闭
common.getSpgzSfkq = function(spkzMap, orderType, def) {
	var sfkq = spkzMap[orderType] ? (spkzMap[orderType]['sfkq'] || def) : def
	return sfkq
}
//  获取总公司id
common.getCompId = function() {
	var compId = window.apiconfig.compId;
	return compId;
}
//  获取总公司信息
common.getConfigData = function() {
	var configData = window.apiconfig || {};
	return configData;
}
// 获取风格配置信息
common.getPageSettingInfo = function(){
	var obj = common.sessionget('pageSettingInfo') || {}
	return obj
}

// 获取用户信息
common.getUserInfo = function() {
	let userInfo = common.localget('user_info') || common.sessionget('user_info') || {};
	userInfo.empInfo = userInfo.empInfo || {};
	userInfo.vipCorp = userInfo.vipCorp || {};
	return userInfo
}
// 获取最低员工职级
common.getMinEmpRank = function(cxrList) {
	var minEmp = {},
		minEmpRank = -1;
	if (cxrList.length > 0) {
		for (var k = 0; k < cxrList.length; k++) {
			let empRank = cxrList[k]['empRank'] || 0;
			/*
			 * 描述：1.如果最低员工职级为 -1时，最低员工存在，且大于-1 则取最低员工职级   
			 *      2.如果员工职级小于最低职级
			 */
			if ((minEmpRank < 0 && empRank > -1) || (empRank < minEmpRank)) {
				minEmpRank = empRank;
				minEmp = cxrList[k];
			}
		}
	}

	return minEmp
}

/*
* 身份证验证 返回年龄为周岁
*   满足周岁条件是 次年出生日期的第二天启， 为1周岁
*   2010-10-10 至 2022-10-11 即为12周岁
*   1. 身份证 号码验证 18位 7-14位 出生年月日计算，
*   2. 除身份证外其他证件一律已出生年月日计算
*
*   参数条件 *type, *idAndBirthday 必传
*   type == 1 idAndBirthday：为身份证件号
*   type != 1 idAndBirthday 传入出生年月日
*
*/
common.verifyIdentity = function(type, idAndBirthday){
  var age = 0;
  if(type == "" || type == undefined){
  	return 
  }
  if(!idAndBirthday){
  	return 
  }  
  
  
  //type==1， idAndBirthday 传证件号
  //type!=1, idAndBirthday 出生日期
  age = getIdCradYear(type,idAndBirthday)
  return age
}
// 根据身份证信息， 返回年龄
function getIdCradYear(type,idAndBirth){
	
  // 获取出生年月日
  var yearBrith = '';
  var monthBrith = '';
  var dayBrith = '';
  if(type==1){
    yearBrith= idAndBirth.substring(6,10);
    monthBrith = idAndBirth.substring(10,12);
    dayBrith = idAndBirth.substring(12,14);
  }else{
    // 这里处理传入的出生年月日可能为 20220708 的问题
    if(idAndBirth.length==10){
    	yearBrith = idAndBirth.substring(0,4);
    	monthBrith = idAndBirth.substring(5,7);
    	dayBrith = idAndBirth.substring(8,10);
    }else if(idAndBirth.length==8){
    	yearBrith = idAndBirth.substring(0,4);
    	monthBrith = idAndBirth.substring(4,6);
    	dayBrith = idAndBirth.substring(6);
    }
  }


  // 获取当前年月日 计算年龄
  var myDate = new Date();
  var monthNow = myDate.getMonth()+1;
  var dayNow = myDate.getDate();
  var age = myDate.getFullYear()-yearBrith;

  if(monthNow < (monthBrith-0) || (monthNow == monthBrith && dayNow <= (dayBrith-0))){
    age--
  }
  return age
}

// 处理出行人证件集合问题
common.chuLiIdCardList = function(cxrList, proType) {
	var newCxrList = common.deepCopy(cxrList);
	newCxrList = newCxrList.map((cxrItem, cxrIndex) => {
		// 1.处理出差申请单中的vipIdcardResponses问题： 转化为 idcardList
		if (!cxrItem.idcardList && cxrItem.vipIdcardResponses) {
			cxrItem.idcardList = cxrItem.vipIdcardResponses || [];
		}
		cxrItem.idcardList = cxrItem.idcardList || [];
		// 2. 补充证件集合的数据结构
		var canTypeList = common.getIdCardTypeList(proType);
		canTypeList.forEach((typeItem, cardIndex) => {
			var idType = typeItem.id;
			// 判断idcardList 集合中有当前的证件类型否？没有，创建一个，push进去
			var newCardItem = common.getOneCardInfo(idType);
			var isCun = false;
			for (var k = 0; k < cxrItem.idcardList.length; k++) {
				var cardItem = cxrItem.idcardList[k] || {};
				if (cardItem['idType'] == idType) {
					isCun = true;
					//补全不存在的属性
					for (var cKey in newCardItem) {
						cardItem[cKey] = cardItem[cKey] || newCardItem[cKey];
					}
					cxrItem.idcardList[k] = cardItem;
				}
			}
			// 如果不存在该证件类型补充 // 3.用主表的信息填充 idcardList的身份证和护照的数据

			if (!isCun) {
				if (idType == 1) {
					newCardItem.idNo = cxrItem.idNumber || '';
				} else if (idType == 2) {
					newCardItem.idNo = cxrItem.passport || '';
					newCardItem.validDate = cxrItem.passportPeriod || '';
					newCardItem.placeOfIssue = cxrItem.placeOfIssue || '';
				}
				cxrItem.idcardList.push(newCardItem)
			}
		})

		// 处理性别
		if(!cxrItem.sex) {
			let lastCode = (cxrItem.idNumber || '').toString().slice(-2,-1);
			cxrItem.sex = lastCode % 2 == 0? 'F': 'M'; 
		}

		// 处理默认使用的证件类型
		if (proType == '10901') {
			cxrItem.useCardType = cxrItem.useCardType || '1'
		} else if (proType == '10902') { // 国际机票
			cxrItem.useCardType = cxrItem.useCardType || '2'
		} else if (proType == '10903') { // 国内酒店
			cxrItem.useCardType = cxrItem.useCardType || '1'
		} else if (proType == '10904') { // 火车票
			cxrItem.useCardType = cxrItem.useCardType || '1'
		} else {
			cxrItem.useCardType = cxrItem.useCardType || '1'
		}

		return cxrItem

	})

	return newCxrList
}
// 获取单个证件信息的对象
common.getOneCardInfo = function(idType) {
	var obj = {
		empId: "",
		firstName: "",
		id: "",
		idNo: "",
		idType: idType,
		lastName: "",
		placeOfIssue: "",
		placeOfIssueName: "",
		validDate: '',
	}
	return obj
}

// 获取某业务产品，可以使用的证件类型
// 证件类型  1 身份证  2 护照  3 港澳通行证   4 台胞证  5 回乡证  6 台湾通行证  7 学生证  8 军官证  9 其他证件
common.getIdCardTypeList = function(proType) {
	var idTypeList = [ // 证件类型
		{
			id: '1',
			text: '中国居民身份证',
		},
		{
			id: '2',
			text: '护照',
		},
		{
			id: '3',
			text: '港澳通行证',
		},
		{
			id: '4',
			text: '台胞证',
		},
		{
			id: '5',
			text: '回乡证',
		},
		{
			id: '6',
			text: '台湾通行证',
		},
		{
			id: '7',
			text: '学生证',
		},
		{
			id: '8',
			text: '军官证',
		},
		{
			id: '9',
			text: '其他证件',
		},
		{
			id: '10',
			text: '外国人永久居留证',
		}
	];
	var canBookArr = [];
	if (proType == '10901') { // 国内机票
		canBookArr = [1, 2, 3, 4, 5, 8, 9];
	} else if (proType == '10902') { // 国际机票
		canBookArr = [2, 3, 4, 5, 6, 9];
	} else if (proType == '10903') { // 国内酒店
		canBookArr = [1, 2];
	} else if (proType == '10904') { // 火车票
		canBookArr = [1, 2, 3, 4, 10];
	}

	var newArr = idTypeList.filter((idTypeItem, index) => {
		var idType = idTypeItem.id;
		if (canBookArr.length > 0) {
			var newValidArr = canBookArr.filter((item) => {
				return item == idType
			}) || [];
			return newValidArr.length > 0
		} else {
			return true
		}

	}) || [];
	return newArr

}

// 数组排序 property->要排序的的数组的属性 例： var arr = [{age:1,name:'lalal'},{age:3,name:'qw22'},{age:2,name:'wawu'}]; 用法 arr.sort(common.compare('age'));
common.compare = function(property) {
	return function(a, b) {
		var value1 = a[property];
		var value2 = b[property];
		if (value1 < value2) {
			return -1;
		} else if (value1 > value2) {
			return 1;
		} else {
			return 0;
		}
	}
}


// 匹配字母 和 /
common.regZiMu = function(str) {
	var bool = true;
	var re = /^[a-zA-Z]+[\/]?[a-zA-Z]+$/;
	if (!re.test(str)) {
		bool = false
	}
	return bool
}

// 匹配汉字
common.regHanZi = function(str) {
	var bool = true;
	// 常用汉字
	// var re = /^[\u4e00-\u9fa5]+$/;
	// 常用汉字 + 常用汉字
	const re = /^[\u4E00-\u9FFF\u3400-\u4DBF\u20000-\u2A6DF\u2A700-\u2B73F\u2B740-\u2B81F\u2B820-\u2CEAF\uF900-\uFAFF\u2F800-\u2FA1F]+$/;
	if (!re.test(str)) {
		bool = false
	}

	return bool
}

// 匹配英文名字     英文字母 + 斜杠 + 空格
common.regEnglishName = function(str){
	var bool = true;
	var re = /^[a-zA-Z\s\/]+$/;
	if (!re.test(str)) {
		bool = false
	}
	return bool
}

/**
 *
 * @desc   url参数转对象
 * @param  {String} url  default: window.location.href
 * @return {Object}
 */
common.parseQueryString = function(url) {
	url = url === null || url === undefined ? window.location.href : url;
	var i = url.indexOf("?");
	var search = "",
		kvs = [];
	if (i == -1) {
		search = url;
	} else {
		search = url.substring(i + 1) || "";
		kvs = search.split("&") || [];
	}

	var obj = {};
	for (var j = 0, l = kvs.length; j < l; j++) {
		var kv = kvs[j].split("=");
		obj[decodeURIComponent(kv[0])] = decodeURIComponent(kv[1]) || "";
	}
	return obj;
}

common.parseQuerystr = function(url) {
	url = url == null ? window.location.href : url;
	if (url.indexOf('?') == -1) {
		return {}
	}
	var search = window.location.search;
	if (search.indexOf('?') > -1) {
		search = search.replace('?', '');
	}
	if (!search) {
		return {}
	}
	var obj = {};
	try {
		obj = JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,
				'":"') +
			'"}')
	} catch (e) {

	}
	return obj
}

// 获取域名
common.getDomain = function() {
	var domain = document.domain;
	var ip = /^(\d+)\.(\d+)\.(\d+)\.(\d+)$/;
	var protocol = window.location.protocol
	var path = '';
	if (ip.test(domain)) {
		var port = window.location.port
		path = protocol + "//" + domain + ":" + port;
	} else {
		path = domain
	}
	return path
}
// 获取反转域名
common.getReverseDomain = function() {
	var domain = common.getDomain();
	var str = domain.split('').reverse().join('');
	return str
}
// 判断是否是费控系统
common.isKaiQiFeiKong = function(){
  let userInfo =  common.getUserInfo();
  let bool = false;
  if(userInfo.vipCorp && userInfo.vipCorp.enableCost == 1){
    bool = true;
  }
  return bool

}

/*
 * bookDate:下单时间,格式如 "2018-08-08 05:05:05",
 * timeOut:距离下单时间多久后停止倒计时,单位为分
 * interval:定时器周期,默认为1s
 * outCallback:超时后的回调
 * onCallback:定时器进行中的回调
 * date:当前时间的时间戳,后台返回
 */
common.countdown = function(param) {
	var noop = function() {};
	if (!param.bookDate) { //下单时间必传
		return;
	}
	let timeOut = 0;
	if(param.timeOut==undefined || param.timeOut==null || param.timeOut==""){
		timeOut = 30;
	}else{
		timeOut = param.timeOut;
	}
	
	param.outCallback = param.outCallback || noop;
	param.onCallback = param.onCallback || noop;
	param.timeOut = timeOut;
	param.interval = param.interval || 1;
	var bookTime = this.getBookTime(param.bookDate);
	common.startInterval(bookTime, param);

}
//获取预订时间(倒计时用)
common.getBookTime = function(bookDate) {
	var arr = bookDate.split(' ');
	var ymd = arr[0].split('-'),
		hms = arr[1].split(':');
	var year = ymd[0] - 0,
		month = ymd[1] - 0,
		day = ymd[2] - 0;
	var hour = hms[0] - 0,
		minute = hms[1] - 0,
		second = hms[2] - 0;
	var bookTime = new Date(year, month - 1, day, hour, minute, second);
	return bookTime;
}
//开始倒计时
common.startInterval = function(bookTime, param) {
	
	var now = param.date?param.date:new Date();
	var subsec = parseInt((bookTime - now + param.timeOut * 60 * 1000) / 1000); //到截止时间秒
	var timerId;
	var $route = param.target.$route || {};
	var path = $route.path || '';
	var timer = function() {
		//如果当前用户已经离开了定时器的定义页面,则清除这个定时器
		if (path && window.location.href.indexOf(path) == -1) {
			clearTimeout(timerId);
			timerId = null;
			return false;
		}

		//未超时
		if (subsec > 0) {
			var sec = subsec % 60;
			var min = parseInt(subsec / 60);
			sec = sec > 9 ? '' + sec : '0' + sec;
			min = min > 9 ? '' + min : '0' + min;
			param.onCallback.call(param.target, {
				secondOut: sec,
				minutsOut: min
			});
			timerId = setTimeout(timer, 1000);
		} else {
			clearTimeout(timerId);

			//超时
			param.outCallback.call(param.target);
		}
		subsec = subsec - param.interval;
	}
	timer();
}
common.getPrecision=function(num) {
	const numStr = num.toString();
	const dotIndex = numStr.indexOf(".");
	if (dotIndex === -1) {
		return 0;
	}
	return numStr.length - dotIndex - 1;
}
// 加法运算函数：
common.add = function(num1, num2){
	const precision = Math.max(common.getPrecision(num1), common.getPrecision(num2));
	const multiple = Math.pow(10, precision);
	return (num1 * multiple + num2 * multiple) / multiple;
}
//减法运算函数：
common.subtract = function(num1, num2){
	const precision = Math.max(common.getPrecision(num1), common.getPrecision(num2));
	const multiple = Math.pow(10, precision);
	return (num1 * multiple - num2 * multiple) / multiple;
}
// 除法运算函数：
common.divide = function(num1, num2){
	const precision1 = common.getPrecision(num1);
	const precision2 = common.getPrecision(num2);
	const multiple = Math.pow(10, precision1 + precision2);
	return (num1 * multiple) / (num2 * multiple);
}
/**
* 乘法运算，避免数据相乘小数点后产生多位数和计算精度损失。
*
* @param num1被乘数 | @param num2乘数
*/
common.numMulti = function(num1, num2) {
        var baseNum = 0;
        try {
        baseNum += num1.toString().split(".")[1].length;
        } catch (e) {
        }
        try {
        baseNum += num2.toString().split(".")[1].length;
        } catch (e) {
        }
        return Number(num1.toString().replace(".", "")) * Number(num2.toString().replace(".", "")) / Math.pow(10, baseNum);
    };

// 处理浮点型 加 减  数据问题  目前计算是加法
common.addition = function(...arr){
	// 数组中值需要字符串型， 防止出现数值型 ， 可以传入数组
	var newArr = arr.flat(Infinity).map(String);
	if(newArr.length==0){
		return 0
	}
	// 还要判断下 整数问题
	var maxLength = newArr[0].split(".").length==2? newArr[0].split(".")[1].length:0;
	var baseNum;
	// 获取最长浮点数（小数点后最长）
	for(var i=1; i<newArr.length; i++){
		if(newArr[i].split(".").length==2){
			if(maxLength<newArr[i].split(".")[1].length){
			    maxLength = newArr[i].split(".")[1].length;
			}
		}
	}  
	// 通过数值对象方法 拿到10 的次幂 （多加一次，防止浮点型问题除不净）
	baseNum = Math.pow(10, (maxLength+1)); 
	// 默认为0
	var total = 0;
	
	// 相加
	for(var j=0; j<newArr.length; j++){
		total+=(newArr[j]!='undefined' && newArr[j]!="" && newArr[j]!= "null" && newArr[j]!= null && newArr[j]!= undefined?newArr[j]*baseNum:0)
	}  
	// 返回计算数据
	return total/baseNum
}

//加法函数，用来得到精确的加法结果
//说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果。
//调用：addPrice(arr)  ;入参为 各个价格的数组集合 
//返回值 返回合计
common.addPrice=function(priceArr){
    // arguments
    priceArr = priceArr || [];
    // 求精确计算，需要扩大的最大倍数 默认1
    var maxArr = [];
   for(var k=0;k<priceArr.length;k++){
       var argItem = priceArr[k] || 0;
       var rArr =  argItem.toString().split(".") || [];
       var rItem =rArr.length>1?rArr[1].length:1;
       maxArr.push(rItem)
   }
   let m = 1;
   m=Math.pow(10,Math.max(...maxArr));
   // 各项乘以最大倍数后，合计计算
   let price = 0 ;
   for(var k=0;k<priceArr.length;k++){
       var argItem = priceArr[k] || 0;
       price += argItem*m;
   }

   // 返回最终结果
   return price/m;
 }

 //获取天数差,参数为字符串格式的时间,如:2018-08-08,返回值为两个日期相隔的天数
common.getDiffDate = function(dat1, dat2) {
    let myDate = new Date((new Date).getTime() + 8*60*60*1000);
    let time1 = myDate.toJSON().split('T').join(' ').substr(0,19);
    let time2 = (myDate.toJSON().split('T').join(' ').substr(0,19))+1;

    dat1 =dat1? dat1.split('-') :time1 ;
    var year1 = dat1[0] - 0,
      month1 = dat1[1] - 1,
      d1 = dat1[2] - 0;
    dat2 =dat2? dat2.split('-') : time2;
    var year2 = dat2[0] - 0,
      month2 = dat2[1] - 1,
      d2 = dat2[2] - 0;
    var ymd1 = new Date(year1, month1, d1, 0, 0, 0);
    var ymd2 = new Date(year2, month2, d2, 0, 0, 0);
    return (ymd2 - ymd1) / 1000 / 3600 / 24;
  }

// 由时间戳转换日期
common.getTimeConversionDate = function(timestamp){
	if (timestamp === 0 || timestamp == null){
	    return ''
	} 
	var date = new Date(timestamp)
	var Y = date.getFullYear() + '-';
	var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
	var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
	var H = date.getHours() + ':'
	var M2 = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
	var S = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
	return Y + M + D+' '+H + M2 + S
}
common.getNewDepartDate = function({departDate, routeDateStart, routeDateEnd}){
  var currentDate = common.getTimeConversionDate(new Date().getTime()).split(' ')[0];
  var startDate = departDate||routeDateStart;
  if(
    startDate < currentDate &&
    currentDate >= routeDateStart
  ){
    return currentDate<=routeDateEnd? currentDate: '0000-00-00';
  }
  return startDate;
}
// 获取今天   明天   仅处理
common.getDateMing = function(strTime, num) {
	var dat;
	/*ios时间格式转换*/
	if (strTime && typeof(strTime) == 'string') {
		strTime = strTime.replace(' ', ',');
		strTime = strTime.replace(/-/g, ',');
		strTime = strTime.replace(/:/g, ',');
		strTime = strTime.split(',');
		if (strTime.length >= 3) {
			var dat = new Date(strTime[0] - 0, strTime[1] - 1, strTime[2] - 0, (strTime[3] || 0) - 0, (strTime[4] ||
					0) - 0,
				(strTime[5] || 0) - 0);
		} else {
			dat = new Date();
		}
	} else {
		dat = new Date()
	}

	(!num) && (num = 0);
	dat = dat.getTime() + 24 * 60 * 60 * 1000 * num;
	dat = new Date(dat);
	var y = dat.getFullYear();
	var m = dat.getMonth() + 1;
	var d = dat.getDate();
	if (d < 10) {
		d = "0" + d
	}
	if (m < 10) {
		m = "0" + m
	};
	return y + "-" + m + "-" + d;
}

export default common
