<template>
  <div>
    <el-card class="reserve">
      <span class="clearfix">预订信息</span>
      <el-checkbox-group style="text-align: left; margin-top:20px" v-if="gkfsRole == 1 && tripType == 1" :min="1"
        v-model="checkList">
        <el-checkbox :label="item" v-for="item in cxrList" :key="item.personId">{{ item.cxrName ||
          item.empName }}</el-checkbox>
      </el-checkbox-group>
      <el-divider></el-divider>
      <div class="reserve-center">
        <div class="date">
          <p>入离日期</p>
          <el-date-picker style="width: 350px" v-model="value1" :disabled="isShowTime"
            :picker-options="pickerOptions(bookDateRange.minDay, bookDateRange.maxDay)" value-format="yyyy-MM-dd"
            clear-icon type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
          <span>共{{ diffDay }}晚</span>
          <div class="btn" @click="selectPrice">
            {{ isShowTime ? '重选日期' : '查询报价' }}
          </div>
        </div>
        <div class="hotel">
          <p>房间数</p>
          <p v-if="gkfsRole == 1 && tripType == 1">{{ roomNum }}间</p>
          <el-input-number v-else v-model="roomNum" @change="handleChange" :min="1" :max="5" label="房间数量">
          </el-input-number>

        </div>
      </div>
      <el-divider></el-divider>
      <div class="bottom-btn">
        <p>支付总价</p>
        <p>￥{{ price }}</p>
      </div>
    </el-card>
    <el-card class="reserve person">
      <div class="header">
        <div class="header-left">
          <span class="clearfix">入住人信息</span>
          <p>入住人姓名须与入住时所持证件的姓名一致</p>
        </div>
        <div class="header-right">
          <!-- <p v-if="gkfsRole!=1 && tripType!=1" @click="cxrVisible = !cxrVisible">选择入住人</p> -->
          <p v-if="tripType == 2" @click="cxrVisible = !cxrVisible">选择入住人</p>
          <p @click="dialogShow = !dialogShow">调整入住方案</p>
        </div>
      </div>
      <el-divider></el-divider>
      <div class="center" v-for="(item, index) in cusRooms" :key="item.index">
        <div class="center-left">房间{{ index + 1 }}</div>
        <div class="center-right">
          <span v-for="(cItem, cIndex) in item.customers" :key="cItem.empId + cIndex">
            <el-input v-model="cItem.name" :disabled="disabled" style="width: 139px;margin-right: 30px;"
              placeholder="暂无入住人"></el-input>
          </span>
          <!--此处不在使用单独input ，数组变化时会报错，-->
          <!-- <el-input
						v-model="item.customers[0].name"
                        :disabled="disabled"
						style="width: 139px"
						placeholder="暂无入住人"
					></el-input>
					<el-input
						v-model="item.customers[1].name"
                        :disabled="disabled"
						style="width: 139px"
						placeholder="暂无入住人"
					></el-input> -->
          <i v-if="gkfsRole != 1 && tripType != 1" class="el-icon-close" style="font-size: 20px"
            @click="delectRoom(index)"></i>
        </div>
      </div>
      <el-divider class="main_divider"></el-divider>
      <div class="center">
        <div class="center-left" style="width: 116px;text-align: left;line-height: 34px;">最晚到店时间</div>
        <div class="center-right" style="width: 600px;margin-left: 0;">
          <el-select style="width: 30%" v-model="lastTimeValue" placeholder="最晚到店时间">
            <el-option v-for="item in lastTimeOptions" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <p class="ruler">酒店在14:00办理入住，早到可能需要等待</p>
        </div>
      </div>
    </el-card>
    <!-- 此处现在只支持国内酒店 -->
    <yj-travel-info 
    ref="htCnTravelInfo"  
    v-if="htCnQuery.tripType == 1" 
      :orderType="11007" 
      :proType="10903" 
      :cxrList="checkList"
      :clbzMap="htCnQuery" 
      :policy="policy" 
      @getCxrList="getCxrList" 
      @chooseProject="chooseProject" 
      @chooseSpgz="getSpgz"
      @selectReason="getWbReason" 
      @spgzList="getSpgzList"
      :showApplyRule="showApplyRule"
    ></yj-travel-info>

    <el-card class="reserve person travel">
      <div class="header">
        <div class="header-left">
          <span class="clearfix">联系信息</span>
        </div>
      </div>
      <div class="contact">
        <div class="travel-div" style="margin-right: 50px">
          <p>联系人姓名</p>
          <el-input v-model="userInfo.empName" placeholder="输入联系人姓名"></el-input>
        </div>
        <div class="travel-div" style="flex: 1">
          <p>联系人电话</p>
          <el-input v-model="userInfo.phoneNumber" placeholder="联系人电话"></el-input>
        </div>
      </div>
    </el-card>

    <el-card class="reserve person travel">
      <div class="header">
        <div class="header-left">
          <span class="clearfix">嘉享积分</span>
        </div>
      </div>
      <div class="contact flex-c">
        <span>离店后可获得</span>
        <span style="color: #ff6605">+{{ !!intergralObj ? intergralObj.points : 0 }}积分</span>
      </div>
    </el-card>

    <el-card class="reserve person">
      <div class="header">
        <div class="header-left">
          <span class="clearfix">报销凭证</span>
        </div>
      </div>
      <div class="reim">
        <el-radio v-model="radio" label="1">公司统一配送</el-radio>
        <el-radio v-model="radio" label="2">不需要报销凭证</el-radio>
        <el-radio v-model="radio" label="3">一单一送</el-radio>
      </div>
    </el-card>

    <div class="text-left" v-if="showAgree">
      <el-checkbox v-model="autoRegister" label="1" class="mgt20">&nbsp;</el-checkbox>
      <span>我已阅读并同意<a class="font13 blue pointer" @click="toTerms()">《酒店集团企业会员计划与服务条款》</a></span>
    </div>

    <!-- <div class="tye-btn" :loading="false" @click="generateOrder">下一步</div> -->

    <el-button class="tye-btn" type="primary" @click="checkAutoRegister" :loading="btnLoading">{{ btnLoading ? '加载中' : '下一步' }}
    </el-button>
    <roomDrag :dialogShow.sync="dialogShow" @handlerQuery="handlerQuery" />
    <yj-cxr-list :visible="cxrVisible" :maxLength="10" :tripType="tripType" :cxrList="currentCxrList"
      @chooseCxrList="chooseCxrList" @cancleCxrList="closeCxr"></yj-cxr-list>
  </div>
</template>
<script>
import mixin from './../../../mixin';
import roomDrag from './roomDrag.vue';

import {
Loading
} from 'element-ui';
import {
mapGetters,
mapMutations, mapState
} from 'vuex';
// http://222.128.98.250:8090/1.0.0/vipFeeSetDetail   // 服务费接口
// bussType (integer, optional): 企业协议价或服务商协议价,1是服务商,2是企业协议价 ,
// fyid (string, optional): 供应商编号 ,
// prodType (integer, optional): 产品类型 ,
// serviceType (string, optional): 业务类型
//http://222.128.98.250:8090/1.0.0/ht/st/hotelDetail
// http://222.128.98.250:8090/1.0.0/costCenterFindList

// 当为出差申请单模式下，无法编辑房间数量 和房间入住人信息，只有在单订和因私模式下才可以

export default {
  mixins: [mixin.ccsqdMixin],
  props: {
    infoObj: {
      type: Object,
      default() {
        return {
          spgzList: [],
        };
      }
    },
  },
  data() {
    return {
      showAgree: false, // 是否展示 酒店集团积分互通自动注册功能
      autoRegister: 0, // 是否选择同意并注册酒店集团 1-选择 0-未选择
      checkList: [],
      disabled: false,
      dialogShow: false,
      cxrVisible: false,
      loadingInstance: '',
      currentCxrList: [],
      btnLoading: false,
      userInfo: {
        empName: '',
        phoneNumber: '',
      },
      isShowTime: true,
      value1: [], //时间
      totalRate: '',
      chargeunit: 1,
      servicePrice: '', //服务费价格
      diffDay: '', //计算出来的时间天数
      roomNum: '', //房间数,
      radio: "1", //报销
      ninghtRooms: [],
      isFreeMeal: 0,
      lastTimeValue: '16:00',
      cusRooms: [{
        customers: [{
          empDeptId: '',
          name: '',
          empId: ''
        },
        {
          empDeptId: '',
          name: '',
          empId: ''
        },
        ],
      },],
      cusRoomsCopy: [],

      // pickerOptions: {
      //   disabledDate(time) {
      //     return time.getTime() < Date.now() - 8.64e7
      //   },
      // },
      lastTimeOptions: [{
        value: "16:00",
        label: '14:00-16:00'
      },
      {
        value: "18:00",
        label: '16:00-18:00'
      },
      {
        value: "20:00",
        label: '18:00-20:00'
      },
      {
        value: "22:00",
        label: '20:00-22:00'
      },
      {
        value: "23:59",
        label: '22:00-23:59'
      },
      {
        value: "06:00",
        label: '01:00-06:00(次日)'
      },
      ],
      value: '',
      spgzInfo: { // 审批规则信息
        gzId: '',
        gzmc: '',
        gzdm: '',
      },
      projectInfo: { // 出差项目信息
        projectCode: "", //出差项目代码 ,
        projectId: "", //出差项目id ,
        projectName: "", //项目名称 ,
      },
      costCenterInfo: { // 成本中心信息
        costCenterCode: "", //成本中心代码 ,
        costCenterId: "", //成本中心id ,
        costCenterName: '', //
      },
      intergralObj: null,
      currentP: 0,
      showApplyRule: true,
    }
  },

  components: {
    roomDrag,
  },
  watch: {
    value1: {
      handler() {
        this.diffDay = this.$dateTime.getDiffDay2(
          this.value1[0],
          this.value1[1]
        )
        this.SAVE_NIGHT(this.diffDay)
      },
      deep: true,
    },
    checkList: {
      handler(val) {
        //选中人变化的时候重新编排房间
        console.log(val);
        this.chooseCxrList(val)
      },
      deep: true
    },
    cusRooms: {
      handler(val) {
        this.roomNum = val.length
        //做调整入住人的时候应该当cusRooms发生变化的时候就需要开始触发
        // 同时在生命周期初始化的时候也需要触发一次
        let rommsCopy = this.$common.deepCopy(val) //
        this.COPY_ROOMS(rommsCopy)
      },
      // immediate: true,
      deep: true,
    },
    roomNum(newval) {
      this.SAVE_ROOMS(newval)
    },
    price(newVal) {
      this.SAVE_PRICE(newVal)
    },
  },
  computed: {
    ...mapState({
      cusRoomsState: state => state.htCn.cusRoomsState,
      htCnQuery: state => state.htCn.htCnQuery, // 
      htCnCcsqD: state => state.htCn.htCnCcsqD,
      ccsqdInfo: state => state.common.ccsqdInfo, // 出差申请单信息
      cxrInfo: state => state.htCn.cxrList,
      lsrList: state => state.htCn.lsrList,
      travelPolicy: state => state.htCn.htCnPolicy,
      ninghtRomms: (state) => state.htCn.ninghtRomms,
      hotelInfo: (state) => state.htCn.hotelInfo,
    }),
    ...mapGetters({
      getVipParamterByParNo: 'common/getVipParamterByParNo',
    }),
    policy() {
      return this.travelPolicy?.policy ?? {}
    },
    // 日期最小，和最大值对象
    bookDateRange() {
      // 方法来自于mixins
      let dateRangeObj = this.getBookDateRange(10903, this.htCnQuery.tripType, 30, this.htCnCcsqD.ccsqdId, this.htCnCcsqD.routeId);
      return dateRangeObj
    },
    gkfsRole() {
      return this.htCnQuery.gkfsRole //默认  0不管控 1： 管控  2安差标管控
    },
    // 判断是否有超标
    isWbPromptBool() {
      let ht20043 = this.getVipParamterByParNo(20043, 0),
        isWb = ht20043 == 1 && this.tripType === 1 && this.htCnQuery.weiType != 1,
        policy = this.travelPolicy.policy || {},
        jdjgbz = policy.jdjgbz ? policy.jdjgbz - 0 : '';

      // 管控方式 超标自付不需要管控审批
      let gkfs; // 差旅标准价
      if (!!policy.gkfs) {
        gkfs = policy.gkfs - 0;
      }
      if (gkfs == 3 || gkfs == 4) {
        return false;
      }
      if (isWb) {
        for (let item of this.ninghtRomms) {
          if (jdjgbz && jdjgbz < item.salePrice) {
            return true;
          }
        }
      }
      
      return false;
    },
    fyys() {
      return this.htCnCcsqD.currentRoute.fyys
    },
    tripType() {
      /* 默认当前因公因私*/
      return this.htCnQuery.tripType
    },
    bookStyle() { // 1单订模式  2出差申请单模式
      return this.$common.getUserInfo().vipCorp.bookStyle
    },
    cxrList() {
      // 出行人数据该为单独处理， 单订与出差单申请单公用一个出行人数据
      if (this.tripType == 2) {
        return this.lsrList || []
      } else {
        return this.cxrInfo || [];
      }

    },
    price() {
      //计算出来的最后价格
      let price;
      if (this.chargeunit == 1) {
        price = this.servicePrice * this.roomNum * this.diffDay
      } else {
        price = this.servicePrice
      }
      // console.log(price,this.totalRate,this.roomNum,this.diffDay,"price,this.totalRate")
      this.SAVE_SERVICE_PRICE(price)
      let p = this.totalRate * this.roomNum + (price);
      this.getHtAmountPoints(p);
      return p;
    }

  },
  created() {
    this.value1 = [this.infoObj.checkInDate, this.infoObj.checkOutDate] //初始化赋值一下
    let userInfo = this.$common.getUserInfo();
    this.userInfo.empName = userInfo.empInfo.empName
    this.userInfo.phoneNumber = userInfo.empInfo.phoneNumber;
    this.userInfo.corpId = userInfo.empInfo.corpId;
    this.checkList = this.cxrList;

    if (this.gkfsRole == 1 && this.tripType == 1) { // 当为因公模式下的出差申请单模式下 直接禁用
      this.disabled = true;

      this.chooseCxrList(this.checkList)
    }
    // 此处增加默认出行人，
    this.currentCxrList = this.cxrList;
    let rommsCopy = this.$common.deepCopy(this.cusRoomsState)
    this.cusRooms = rommsCopy;
  },
  mounted() {
    // 是否展示 酒店集团积分互通自动注册功能  默认不展示
    this.showAgree = this.infoObj.fyid == '12007' && this.getVipParamterByParNo('20037') - 0;
    this.$nextTick(() => {
      this.getPlanSearch()
      this.getServerPrice()
    })
  },

  methods: {
    ...mapMutations({
      SAVE_NIGHT: 'htCn/SAVE_NIGHT',
      SAVE_DESCRIPTION: 'htCn/SAVE_DESCRIPTION',
      SAVE_PRICE: 'htCn/SAVE_PRICE',
      SAVE_ROOMS: 'htCn/SAVE_ROOMS',
      SAVE_SERVICE_PRICE: 'htCn/SAVE_SERVICE_PRICE',
      COPY_ROOMS: 'htCn/COPY_ROOMS',
      SAVE_NIGHT_ROOMS: 'htCn/SAVE_NIGHT_ROOMS',
      SAVE_ISFREEMEAl: 'htCn/SAVE_ISFREEMEAl',
      SET_HTCN_QUERY: 'htCn/SET_HTCN_QUERY', // 保存因共模式下的自定义信息
      SET_HTCN_CCSQD: 'htCn/SET_HTCN_CCSQD', //保存因共模式下的出差申请单信息
      SET_HTCN_POLICY: 'htCn/SET_HTCN_POLICY',
    }),
    pickerOptions(minDay, maxDay) {
      return {
        //定义不可选择时间 暂无预约功能 查找有效日期
        // 查询出差申请单预订行程日期可否变更 0不可更改  1 按行程更改 2 出差申请单日期范围更改
        disabledDate(time) {
          let minTime = new Date(new Date(minDay).toLocaleDateString()).getTime();
          let maxTime = new Date(new Date(maxDay).toLocaleDateString()).getTime();
          return time.getTime() < minTime || time.getTime() > maxTime;
        },
      }
    },
    toTerms() {
      var url = 'https://campaign.huazhu.com/static/activity/?objectClass=qyhy001';
      window.open(url); // h5跳转 
    },
    handlerQuery(list) {
      let rommsCopy = this.$common.deepCopy(list)
      // 选中入住人排列顺序重新赋值
      this.cusRooms = rommsCopy;

      // // console.log(this.$data,"this.$computed")
      // this.cusRoom=this.cusRoomsState
      // // this.$data.cusRooms = Object.assign([], this.cusRoomsState)
      // this.cusRoom=this.$common.deepCopy(this.cusRoomsState) //深拷贝一份房间人数去掉空的数据
      // // console.log(this.cusRoom,"this.cusRoom")
      // 调整入住人
      this.getPlanSearch();
    },
    // 获取审批规则
    getSpgz(info) {
      this.spgzInfo = {
        gzmc: info.gzmc,
        gzdm: info.gzdm,
        gzId: info.gzId,
      }
    },
    // 获取审批规则列表
    getSpgzList(list) {
      this.spgzList = list;
    },
    // 过期违背原因
    getWbReason(obj) {
      this.SET_HTCN_QUERY(obj)
    },
    getCxrList(val) {

      if(val && val.length>0){
        this.checkList = val;
      }
      console.log(val);
      // // 获取成本中心
      // // console.log(val,"获取成本中心")
      // this.costCenterInfo = {
      //   costCenterCode: val.costCenterCode, //成本中心代码 ,
      //   costCenterId: val.costCenterId, //成本中心id ,
      //   costCenterName: val.costCenterName, //
      // }

      
    },
    chooseProject(val) {
      //获取项目

      
      this.projectInfo = {
        projectCode: val.projectCode, //出差项目代码 ,
        projectId: val.projectId, //出差项目id ,
        projectName: val.projectName, //项目名称 ,

      }
    },
    delectRoom(index) {
      this.$data.cusRooms = Object.assign([], this.$data.cusRooms)
      if (this.cusRooms.length > 1) {
        this.cusRooms.splice(index, 1)
      } else {
        this.cusRooms[index].customers[0].name = ''
        this.cusRooms[index].customers[1].name = ''
      }
      let list = [...this.cusRooms];
      let cusRoomsList = [];
      // 此处与选中出行人联动，
      list.forEach(item => {
        for (var i = 0; i < item.customers.length; i++) {
          if (item.customers[i].empId) {
            let obj = {};
            obj = {
              deptId: item.customers[i].empDeptId || '',
              empName: item.customers[i].name || '',
              empId: item.customers[i].empId || '',
            }
            cusRoomsList.push(obj)
          }
        }

      })
      this.currentCxrList = cusRoomsList || [];

    },
    selectPrice() {
      this.isShowTime = !this.isShowTime
      if (this.isShowTime) {
        this.getPlanSearch()
      }
    },
    handleChange(newVal, oldVal) {
      if (newVal > oldVal) {
        let obj = {
          customers: [{
            empDeptId: '',
            name: '',
            empId: ''
          },
          {
            empDeptId: '',
            name: '',
            empId: ''
          },
          ],
        } //计步器生成
        this.cusRooms.push(obj)
      } else {
        this.cusRooms.pop()
      }
      this.SAVE_ROOMS(newVal)
    },
    chooseCxrList(cxrList) {
      console.log(cxrList, "item")
      // 选择出行人处理双向绑定的数组
      this.cxrVisible = false
      if (cxrList && cxrList.length > 0) {
        let newArr = []
        for (let i = 0; i < cxrList.length; i++) {
          let item = cxrList[i] || {};
          let obj = {
            costCenterCode:item.costCenterCode, //(string, optional): 成本中心代码,C端无需传 ,
            costCenterId:item.costCenterId,// (string, optional): 成本中心id,C端无需传 ,
            costCenterName: item.costCenterName,// (string, optional): 成本中心名称,C端无需传 ,
            email:item.email,// (string, optional): Email ,
            phone:item.phoneNumber,
            empDeptId:item.deptId,
            name:item.empName,
            empId:item.empId,
          }
 
          newArr.push(obj)
        }
        let customerList = []
        newArr?.map((item, index, array) => {
          //不超过5个人的时候一人一个房间，超过的时候第六个人在第一个房间，每个房间人数不得超过2人，当没有人的时候生成一个空对象避免双向绑定失败
          let objParams = {}
          if (newArr.length > 5) {
            if (index > 4) {
              let indexNum = index - 5
              customerList[indexNum].customers[1] = array[index]
            } else {
              objParams.customers = [array[index]]
              customerList = customerList.concat([objParams])
            }
          } else {
            let obj = {
              empDeptId: '',
              name: '',
              empId: '',
              costCenterCode:'', //(string, optional): 成本中心代码,C端无需传 ,
              costCenterId:'',// (string, optional): 成本中心id,C端无需传 ,
              costCenterName: '',// (string, optional): 成本中心名称,C端无需传 ,
              email:'',// (string, optional): Email ,
              phone:'',
            }
            let customers = {}
            customers.customers = [item]
            customerList = customerList.concat([customers])
            customerList[index].customers[1] = obj
          }
        })
        customerList.forEach((item, index) => {

          if (!item.customers[1]) {
            let obj = {
              empDeptId: '',
              name: '',
              empId: '',
              costCenterCode:'', //(string, optional): 成本中心代码,C端无需传 ,
              costCenterId:'',// (string, optional): 成本中心id,C端无需传 ,
              costCenterName: '',// (string, optional): 成本中心名称,C端无需传 ,
              email:'',// (string, optional): Email ,
              phone:'',
            }
            customerList[index].customers[1] = obj
          }

          item.roomNo = index + 1
        })
        this.cusRooms = customerList
      
        // 深拷贝一份customerList去除没有empDeptId name empId 的数组 作为参数传入生成订单
      } else {
        this.cusRooms = [{
          customers: [{
              empDeptId: '',
              name: '',
              empId: '',
              costCenterCode:'', //(string, optional): 成本中心代码,C端无需传 ,
              costCenterId:'',// (string, optional): 成本中心id,C端无需传 ,
              costCenterName: '',// (string, optional): 成本中心名称,C端无需传 ,
              email:'',// (string, optional): Email ,
              phone:'',
          },
          {
              empDeptId: '',
              name: '',
              empId: '',
              costCenterCode:'', //(string, optional): 成本中心代码,C端无需传 ,
              costCenterId:'',// (string, optional): 成本中心id,C端无需传 ,
              costCenterName: '',// (string, optional): 成本中心名称,C端无需传 ,
              email:'',// (string, optional): Email ,
              phone:'',
          },
          ],
        },]
      }
      // 重新获取差标
      this.getPlanSearch();

    },

    closeCxr() {
      this.cxrVisible = false
    },
    // 房间计划
    getPlanSearch() {
      let params = {
        checkInDate: this.value1[0] || '',
        checkOutDate: this.value1[1] || '',
        fyid: this.infoObj.fyid,
        hotelId: this.infoObj.hotelId,
        payment: this.infoObj.payment,
        ratePlanId: this.infoObj.ratePlanId,
        roomId: this.infoObj.roomId,
        cityId: this.hotelInfo.cityId,
        ccsqdId: this.infoObj.ccsqdId,
        routeId: this.infoObj.routeId,
        tripType: this.tripType, // 因公因私
      };
      // 2023/06/09  13:11 陈伟说： 当没有房间数时，默认按照 一人一间处理
      // 入住人房间：
      params.roomNum = this.cusRooms.length || this.cxrList.length;
      // 入住人数量
      params.checkInNum = this.cxrList.length;

      if (this.cxrList) {
        let minEmpRank = 99999;
        let allRank = [];
        let ids = [];
        let repeat = {};
        let userInfo = this.$common.getUserInfo();
        params.corpId = userInfo.empInfo.corpId;
        this.cxrList.forEach(cxrItem => {
          if (cxrItem.empRank == void 0) cxrItem.empRank = 0;
          if (cxrItem.foreignType == 0 || cxrItem.cxrType == 1) {
            ids.push(cxrItem.empId);
            allRank.indexOf(cxrItem.empRank) == -1 && allRank.push(cxrItem.empRank);
            if (cxrItem.empRank < minEmpRank) {
              minEmpRank = cxrItem.empRank;
            }
          } else {
            // 2023-10-30 bug号：11372 陈伟说 外来人使用预订人差标 传入预订人的id 
            ids.push(userInfo.empInfo.empId)
            repeat[cxrItem.empId] = true;
            allRank.indexOf(userInfo.empInfo.empRank) == -1 && allRank.push(userInfo.empInfo.empRank);
            if (userInfo.empInfo.empRank < minEmpRank) {
              minEmpRank = userInfo.empInfo.empRank;
            }
          }
        });

        if (minEmpRank != 99999) {
          params.minEmpRank = minEmpRank;
          params.allRank = allRank.join(',');
        }
        params.travelerIds = ids;

        let roomEmpMap = {};
        this.cusRooms.forEach((roomItem, roomIdx) => {
          if (!roomEmpMap[roomIdx + 1]) roomEmpMap[roomIdx + 1] = [];
          roomItem.customers.forEach(cxrItem => {
            if (cxrItem.empId) {
              if (repeat[cxrItem.empId]) {
                roomEmpMap[roomIdx + 1].push(userInfo.empInfo.empId);
              }
              roomEmpMap[roomIdx + 1].push(cxrItem.empId);
            }
          });
        });
        params.roomEmpMap = roomEmpMap;

      }
      this.$conn
        .getConn('htbook.planSearch2', {
          jsfile: false
        })(params).then((res) => {
          let ratePlans = res?.data?.rooms[0]?.ratePlans[0] ?? {};
          let travelPolicy = res?.data?.travelPolicy ?? {};
          this.showApplyRule = !(travelPolicy.gkfs == 3 || travelPolicy.gkfs == 4)
          this.totalRate = ratePlans.totalRate ?? ''
          this.SAVE_ISFREEMEAl(ratePlans.freeMeal ?? 0)
          this.SAVE_NIGHT_ROOMS(ratePlans.nightlyRates ?? [])
          this.SAVE_DESCRIPTION(ratePlans.prepayRule?.description ?? '暂无取消规则！');
          this.SET_HTCN_POLICY({ policy: res?.data?.travelPolicy });
          // 差旅标准：本次预订每间房价不得高于 ¥0元/间夜
          this.SET_HTCN_QUERY({
            ...this.htCnQuery,
            violateItem: ratePlans.violate ? `差旅标准：本次预订每间房价不得高于 ¥${travelPolicy.jdjgbz}元/间夜` : null
          })
        })
        .catch((err) => {
          console.log(err, "err")
          this.$message({
            type: 'error',
            message: '该时间段内，酒店已满房，暂无报价！请重新选择日期，或者选择其他房型！',
          })
        })
    },
    getHtAmountPoints(totalPrice) {
      if (this.currentP == totalPrice) return;
      this.currentP = totalPrice;
      var user = this.userInfo || {};
      if (!user.corpId || !this.infoObj || !this.infoObj.fyid) return;
      // 享收嘉享积分才去调获取积分接口
      if (this.infoObj.bussType == 2) return;
      if (!totalPrice) {
        this.intergralObj = null;
        return;
      }
      var query = this.$conn.getConn('intergral.htAmountPoints');
      query({
        supplierId: this.infoObj.fyid,
        corpId: user.corpId,
        payAmount: totalPrice
      }, res => {
        var data = res.data || {};
        this.intergralObj = data;
      }, err => {

      })
    },
    // 服务费计算
    getServerPrice() {
      this.$conn
        .getConn('commu.fee', {
          jsfile: false
        })({
          bussType: this.infoObj.bussType,
          fyid: this.infoObj.fyid,
          prodType: '4',
          serviceType: '10903',
          priceFromInfo: this.$route.query.priceFromInfo,
        })
        .then((res) => {
          console.log(res, "服务费的价格")
          //服务费需要进行因公因私判断
          // feeAutoB (number, optional): 因公自主预订服务费 ,
          // feeAutoP (number, optional): 因私自主预订服务费 ,
          // chargeunit 如果是1 就是房间数*晚数 * 服务费
          // chargeunit 如果是2 那就是服务费是多少钱就是多少钱 
          this.chargeunit = res.data.chargeunit
          if (this.tripType == 1) {
            this.servicePrice = res.data.feeAutoB
          } else {
            this.servicePrice = res.data.feeAutoP
          }

        })
        .catch((err) => {
          this.$message({
            type: 'error',
            message: err.tips,
          })
        })
    },
    checkAutoRegister() {
         // 2.验证差旅信息、项目、成本中心、审批规则
				if(this.tripType == 1){
					var clzcRule = this.$refs['htCnTravelInfo']&&this.$refs['htCnTravelInfo'].validTravelInfo();
					if(!clzcRule.bool){
						this.$message.error(clzcRule.msg)
					  return
					}
				}

      if (this.autoRegister) {

     


        this.$confirm('根据国家个人信息安全法要求，请您在提交订单前仔细阅读酒《酒店集团企业会员计划与服务条款》，相关技术服务由空港嘉华提供，提交订单代表您已阅读并同意上述条款', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.generateOrder();
          })
          .catch(() => {

          })
      } else {
        this.generateOrder();
      }
    },
    generateOrder() {
      if (this.isWbPromptBool) {
        this.$confirm('连住订单均价不超标，如后续出现部分退订可能造成订单超标，请知晓', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            this.generateOrderAjax();
          })
          .catch(() => {

          })
      } else {
        this.generateOrderAjax();
      }
    },
    generateOrderAjax() {
      if (this.cusRooms[0].customers[0].name != '') {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        loading.$el.style.fontSize = "30px";

        this.loadingInstance = Loading.service(loading);
        this.btnLoading = true
        let hotelDataValidateParams = {
          checkInDate: this.value1[0],
          checkOutDate: this.value1[1],
          fyid: this.infoObj.fyid,
          hotelId: this.infoObj.hotelId,
          payment: '2',
          ratePlanId: this.infoObj.ratePlanId,
          roomId: this.infoObj.roomId,
          rooms: this.roomNum,
        }

        this.$conn.getConn('htbook.hotelDataValidate')(hotelDataValidateParams, (res) => {
          console.log(res)
          this.loadingInstance.setText('预定中，请稍等');
          if (res.errMsg == "success") {
            this.getHtBook()
          }
        }, (err) => {
          this.$message({
            type: 'error',
            message: err.tips,
          })
          this.btnLoading = false;
          this.loadingInstance.close();
        })

      } else {
        this.$message({
          type: 'error',
          message: "请先完善入住人信息",
        })
      }
    },
    getHtBook() {

      this.cusRoomsCopy = this.$common.deepCopy(this.cusRooms) //深拷贝一份房间人数去掉空的数据
      this.cusRoomsCopy.map((item, i) => {
        if (item.customers.length > 0) {
          item.customers.forEach((e, index) => {
            if (e.empDeptId == '' && e.empId == '' && e.name == '') {
              item.customers.splice(index, 1);
            }
          })
          if (item.rooms || item.rooms >= 0) {
            item.roomNo = i + 1
          }
          delete item.rooms
        }
        return item
      })
      if (!(this.$route.query.sqdType && this.$route.query.sqdType == 'xak') &&
        this.tripType == 1 && this.bookStyle == 2 && !this.htCnCcsqD.ccsqdNo && !this.htCnCcsqD.routeId) {
        this.$alert('根据差旅政策要求，酒店预订需要出差申请单，未检测到出差申请单，请重新选择申请单，再进行预订！', '提示', {
          confirmButtonText: '确定',
        });
        return false
      }
      let hotelBookParams;
      if (this.$route.query.sqdType == 'xak') {
        hotelBookParams = {
          appId: this.spgzInfo.gzId || '', //审批规则id；C端无需传 ,
          checkInDate: this.value1[0],
          checkOutDate: this.value1[1],
          // clbzj : clbzj,//,差旅标准价,C端无需传
          // creditCard : {},//差旅标准价,C端无需传 ,
          earliestArrivalTime: '', //最早到店时间,现付必传,例如格式为23:50 ,14:20 ,02:30 ,
          hotelId: this.infoObj.hotelId, // 酒店id,不能为空 ,
          latestArrivalTime: this.lastTimeValue, // 最晚到店时间,现付必传,例如格式为23:50 ,14:20 ,02:30 ,
          linkEmail: '', // 联系人邮箱 ,
          linkMobile: this.userInfo.phoneNumber, //联系手机,不能为空 ,
          linkman: this.userInfo.empName, //联系人,不能为空 ,
          noteToHotel: '', //给酒店备注 ,
          orderFrom: '10503', // 订单来源,10503为PC端,10504为安卓终端,10505为苹果终端,10506为微信/H5 ,
          orderRooms: this.cusRoomsCopy, //房间信息 ,
          payment: '2', //支付方式：1现付 2预付,不能为空 ,
          ratePlanId: this.infoObj.ratePlanId, // 价格计划id,不能为空 ,
          roomId: this.infoObj.roomId, //房型id,不能为空 ,
          // travelInfo : travelInfo,//差旅信息,C端无需传 ,
          tripType: 1, // 差旅类型不能为空,1是因公,2是因私.C端固定传2
          totalPrice: this.price,
          minPriceRatePlanId:this.infoObj.minPriceRatePlanId, // 
        }

      } else {
        hotelBookParams = {
          appId: this.spgzInfo.gzId,
          checkInDate: this.value1[0],
          checkOutDate: this.value1[1],
          hotelId: this.infoObj.hotelId,
          latestArrivalTime: this.lastTimeValue,
          linkEmail: "",
          linkMobile: this.userInfo.phoneNumber,
          linkman: this.userInfo.empName,
          noteToHotel: "",
          orderFrom: "10503",
          payment: "2",
          ratePlanId: this.infoObj.ratePlanId,
          roomId: this.infoObj.roomId,
          tripType: this.tripType,
          orderRooms: this.cusRoomsCopy,
          violateCode: this.htCnQuery.violateCode, //违背事项代码
          violateItem: this.htCnQuery.violateItem, //违背事项说明
          reasonCode: this.htCnQuery.reasonCode, //违背原因代码
          reasonDesc: this.htCnQuery.reasonDesc, //违背原因说明
          // clbzj
          // travelInfo
          autoRegister: !this.autoRegister || this.autoRegister == '0' ? 0 : 1,
          delayOrdreType: this.$route.query.delayOrdreType, // 续住标识
          totalPrice: this.price,
          minPriceRatePlanId:this.infoObj.minPriceRatePlanId, // 
        }
      }
      // 管控方式 
      let policy = this.travelPolicy.policy || {},
        gkfs,
        jdjgbz = policy.jdjgbz; // 差旅标准价
      if (!!policy.gkfs) {
        gkfs = policy.gkfs - 0;
      }
      let obj = {
        projectCode: this.projectInfo.projectCode, //出差项目代码 ,
        projectId: this.projectInfo.projectId, //出差项目id ,
        projectName: this.projectInfo.projectName, //项目名称 ,
        costCenterCode: this.costCenterInfo.costCenterCode, //成本中心代码 ,
        costCenterId: this.costCenterInfo.costCenterId, //成本中心id ,
        costCenterName: this.costCenterInfo.costCenterName, //
        violateitemCode: this.htCnQuery.violateitemCode, //违背事项代码
        violateitem: this.htCnQuery.violateItem, //违背事项说明  后台垃圾接口  驼峰命名
        reasonCode: this.htCnQuery.reasonCode, //违背原因代码
        reasonDesc: this.htCnQuery.reasonDesc, //违背原因说明
        gkfs: gkfs, // 管控方式
      }
      // travelInfo 处理
      if (this.bookStyle == 2 && this.tripType == 1) {
        obj.ccsqdId = this.htCnCcsqD.ccsqdId; //出差申请单id ,
        obj.ccsqdNo = this.htCnCcsqD.ccsqdNo; // 出差申请单号 ,
        obj.routeId = this.htCnCcsqD.routeId; //行程id 
      }
      hotelBookParams.travelInfo = obj;
      hotelBookParams.clbzj = jdjgbz;

      let url = this.$route.query.sqdType == 'xak' ? 'xakCcsqd.hotelBook' : 'htbook.book'
      this.$conn.getConn(url, {
        jsfile: false
      })(hotelBookParams, (res) => {
        this.loadingInstance.close();
        this.btnLoading = false
        this.$message({
          type: 'success',
          message: "订单已生成",
        })
        this.$router.push({
          name: 'HtCnOrderDetail',
          query: {
            orderId: res.data.orderId,
            orderType: 11007
          },
        })
      }, (err) => {
        this.btnLoading = false
        this.loadingInstance.close();
        this.$message({
          type: 'error',
          message: err.tips,
        })

      })

    }
  },
}
</script>

<style scoped lang="scss">
.blue {
  color: #1C83ED;
}

.mgt20 {
  margin-top: 20px;
}

.tye-btn {
  width: 100%;
  background: #ff9524;
  font-size: 24px;
  font-family: '微软雅黑';
  font-weight: 600;
  color: #ffffff;
  height: 80px;
  text-align: center;
  line-height: 80px;
  margin-top: 20px;
  margin-bottom: 50px;
  padding: 0;
}

.flex-c {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.reim {
  display: flex;
  margin-top: 30px;
}

/deep/ .el-select .el-input__inner:focus {
  border-color: #c4cddd;
}

.main_divider {
  margin-top: 0px;
}

.ruler {
  color: #b3b3b3;
  font-size: 12px;
  margin-left: 10px;
}

/deep/ .el-card__body {
  display: flex;
  flex-direction: column;
}

.reserve {
  width: 100%;
  // background-color: #fff;
  padding: 10px;
  background-color: #fff;

  .clearfix {
    text-align: left;
    font-size: 20px;
    color: #424242;
    font-weight: bold;
  }

  .reserve-center {
    display: flex;
    flex-direction: column;

    .date {
      display: flex;
      align-items: center;

      p {
        font-size: 14px;
        margin-right: 70px;
      }

      span {
        margin-left: 30px;
        margin-right: 30px;
      }

      .btn {
        width: 120px;
        height: 35px;
        background-color: #006bb9;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
      }
    }

    .hotel {
      display: flex;
      margin-top: 34px;

      p {
        font-size: 14px;
        margin-right: 82px;
      }
    }
  }

  .bottom-btn {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    p:nth-child(1) {
      color: #808080;
    }

    p:nth-child(2) {
      font-size: 33px;
      color: #ff9524;
    }
  }
}

.person {
  margin-top: 27px;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      color: #b3b3b3;
      font-size: 12px;
      margin-left: 10px;
    }

    .header-left {
      display: flex;
      align-items: center;
    }

    .header-right {
      display: flex;
      text-align: right;

      p {
        font-size: 14px;
        color: #006bb9;
      }
    }
  }

  .center {
    display: flex;
    margin-bottom: 20px;

    .center-right {
      margin-left: 82px;
      display: flex;
      align-items: center;

      /deep/ .el-input:nth-child(2) {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }
}

.travel {
  .travel-info {
    width: 100%;
    height: 40px;
    background-color: #f2f8ff;
    display: flex;
    align-items: center;
    color: #8f98a9;
    padding-left: 20px;
    margin-top: 30px;
  }

  .contact {
    display: flex;
  }

  .travel-div {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    p {
      color: #748197;
      font-size: 13px;
      margin-bottom: 10px;
    }

    .travel-person {
      display: flex;

      span {
        width: 150px;
        height: 31.99px;
        border-bottom: 1px solid #c4cddd;
        text-align: center;
        box-sizing: border-box;
        line-height: 31.99px;
        font-weight: bold;
      }
    }
  }

  // /deep/

  /deep/ .el-input__inner {
    border: 0;
    padding: 0;
    border-radius: 0;
    border-bottom: 1px solid #c4cddd;
    font-size: 15px;
    font-weight: bold;
  }
}</style>
